import { getLocalCollectionData } from "./localstorage";
import * as FirebaseService from './firebase';
import User, { Reminder, serializeFirebaseTimestamp } from "./users";

/**
 * @typedef {firebase.firestore.DocumentChange} DocumentChange
 * @typedef {firebase.firestore.DocumentData} DocumentData
 */

export async function getLocalSerializedUsers({ collection }) {
     const { data, lastUpdatedAt } = await getLocalCollectionData({ collection })
     const localUsers = User.serializeLocalData({ data: data || [] });
     return {data: localUsers, lastUpdatedAt};
 }

 export async function getLocalSerializedReminders({ collection }) {
    const { data, lastUpdatedAt } = await getLocalCollectionData({ collection })
    const localUsers = Reminder.serializeLocalData({ data: data || [] });
    return {data: localUsers, lastUpdatedAt};
}

let firebaseListenerRef;
export async function listenUsersData(listener, lastModifiedAt, options) {
    const collection = 'users';
    const listenFirebaseData = async ({docChanges}) => {
        listener({ collection, docChanges})
    };
    firebaseListenerRef && firebaseListenerRef();
    firebaseListenerRef = FirebaseService.listenCollectionData({ collection, lastModifiedAt, listener: listenFirebaseData});
    return firebaseListenerRef;
}

let firebaseReminderListenerRef;
export async function listenRemindersData(listener, lastModifiedAt, options) {
    const collection = 'reminders';
    const listenFirebaseData = async ({docChanges}) => {
        listener({ collection, docChanges})
    };
    firebaseReminderListenerRef && firebaseReminderListenerRef();
    firebaseReminderListenerRef = FirebaseService.listenCollectionData({ collection, lastModifiedAt, listener: listenFirebaseData});
    return firebaseReminderListenerRef;
}

/**
 * Get ISO Date string from Firetore Timestamp
 * @param {firebase.firestore.Timestamp} timestamp
 * @returns {String} ISO formatted date string
 */
export function getDateFromTimestamp(timestamp) {
    const fbTimestamp = serializeFirebaseTimestamp(timestamp);
    return fbTimestamp?.toDate().toISOString();
}
