const { firebaseFns } = require("./firebase");
const _isEqual = require('lodash.isequal');
const _isObject = require('lodash.isobject');
const _transform = require('lodash.transform');
const Faker = require('faker');
Faker.locale = 'en_IND';

export const getCurrentFirebaseTimestamp = () => {
    const {Firestore} = firebaseFns();
    return Firestore.Timestamp.now();
}

export const getFirebaseTimestampFromObject = (obj) => {
    const { Firestore } = firebaseFns();
    if (obj?.seconds && obj?.nanoseconds) {
        return new Firestore.Timestamp(obj.seconds, obj.nanoseconds);
    }
    return null;
}

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function _deepDifference(object, base) {
    function changes(object, base) {
        return _transform(object, function (result, value, key) {
            if (!_isEqual(value, base[key])) {
                result[key] = (_isObject(value) && _isObject(base[key])) ? changes(value, base[key]) : value;
            }
        });
    }
    return changes(object, base);
}

/**
 * Generate new UUID using Faker.js
 * @return {string}
 */
export function getUUID() {
    return Faker.random.uuid();
}

export const AvailableClientLabels = [
    {
        label: 'Call',
        value: 'call',
        color: '#8a6e00'
    },
    {
        label: 'Workout',
        value: 'workout',
        color: '#4c5f03'
    },
    {
        label: 'Meal',
        value: 'meal',
        color: '#e8161b'
    },
    {
        label: 'Chats',
        value: 'chats',
        color: '#0a98c5'
    },
    {
        label: 'B4After',
        value: 'b4after',
        color: '#05ab90'
    },
];