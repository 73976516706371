import { createContext, useContext, useEffect, useReducer } from "react";
import { logger } from '../logger';
import { getDateFromTimestamp, getLocalSerializedReminders, listenRemindersData } from '../../services/api';
import { CollectionItem, Reminder, ReminderList } from "../../services/users";
import { useFirebaseReminderSave } from "./use-firebase-save";
import { updateLocalCollectionData } from "../../services/localstorage";
import React from 'react';

const firebaseReminderContext = createContext(null);

/**
 * @typedef {{status: string, data: ReminderList, error: any}} FirebaseQueryState
 */

/**
* @typedef {{type: string, payload?: any}} DispatchAction
*/

/**
 * @typedef {import("react").Dispatch<DispatchAction>} DispatchWithAction
 */

/**
 * Provider component that wraps generic firebase
 */
export function ProvideFirebaseReminderData({ children }) {
    const {state, dispatch} = useFirebase();
    const { status, data, error } = state;
    const {isSaving} = useFirebaseReminderSave(data);

    return (
        <firebaseReminderContext.Provider value={{ status, data, error, isSaving, dispatch }}>{children}</firebaseReminderContext.Provider>
    );
}

/**
 * Hook for child components to get firebase users
 * @returns {{data: ReminderList, status: String, isSaving: Boolean, dispatch: DispatchWithAction}}
 */
export const useFirebaseReminderData = () => {
    return useContext(firebaseReminderContext);
};

/**
 * @callback ReducerFn
 * @param {FirebaseQueryState} _state
 * @param {DispatchAction} action
 */

// Reducer for hook state and actions
/**
 * Array map callback method
 * @type {ReducerFn}
*/
const reducer = (_state, action) => {
    switch (action.type) {
        case "idle":
            return { status: "idle", data: (_state.data || new ReminderList()), error: undefined };
        case "loading":
            return { status: "loading", data: (_state.data || new ReminderList()), error: undefined };
        case "success":
            return { status: "success", data: action.payload, error: undefined };
        case "updateReminder":
            if (action.payload && action.payload instanceof CollectionItem) {
                logger("Got Updated reminder", action.payload.lastModifiedAt);
                return {
                    ..._state,
                    data: _state.data.updateItem(action.payload)
                };
            }
            return _state;
        case "updateFirebaseChanges":
            const {updated = [], removed = []} = action.payload;
            const updatedList = _state.data.updateList(updated).removeItems(removed);
            updatedList.updateFirebaseTimestamp();
            return {
                ..._state,
                status: "success",
                data: updatedList
            };
        case "updateList":
            if (action.payload) {
                return {
                    data: _state.data.updateList(action.payload),
                    status: "success",
                    error: undefined
                };
            }
            return _state;
        case "error":
            return { status: "error", data: undefined, error: action.payload };
        default:
            throw new Error("invalid action");
    }
}

function handleFirebaseChange({collection, docChanges, dispatch}) {
    const changes = { updated: [], removed: [] }
    docChanges.forEach((docChange) => {
        const changedUser = new Reminder({
            id: docChange.doc.id,
            ref: docChange.doc.ref.path,
            lastModifiedAt: docChange.doc.get('lastModifiedAt'),
            data: docChange.doc.data()
        });
        if (docChange.type === 'removed') {
            changes.removed.push(changedUser)
        } else {
            changes.updated.push(changedUser)
        }
    })
    if (docChanges.length > 0) {
        dispatch({ type: "updateFirebaseChanges", payload: changes });
    }
}

export const useFirebase = () => {
    /**
     * @type {FirebaseQueryState}
     */
    const initialState = {
        status: "idle",
        data: new ReminderList(),
        error: undefined
    };

    /**
    * @type {[FirebaseQueryState, DispatchWithAction]}
    */
    const [state, dispatch] = useReducer(reducer, initialState);
    const collection = "reminders";

    const lastFirebaseUpdate = state?.data?.lastFirebaseUpdateTimestamp;
    useEffect(() => {
        const isoTimestamp = getDateFromTimestamp(state.data.getLastModifiedItemTime());
        if (state.data && isoTimestamp) {
            logger("Saving to local storage", isoTimestamp, collection, state.data.toJSON());
            updateLocalCollectionData({ collection, data: state.data.toJSON(), timestamp: isoTimestamp })
        }
    }, [state.data, lastFirebaseUpdate]);


    useEffect(() => {
        let unsubscribeFirebaseRef;
        dispatch({ type: 'loading' });
        const fetchUserData = async () =>{
            const { data, lastUpdatedAt } = await getLocalSerializedReminders({ collection });
            if (data  && lastUpdatedAt) {
                logger("Found initial data in localstorage", data, lastUpdatedAt);
                dispatch({ type: "updateList", payload: data });
            }
            listenRemindersData(({ docChanges }) => {
                handleFirebaseChange({docChanges, dispatch, collection});
            }, lastUpdatedAt).then((ref) => {
                unsubscribeFirebaseRef = ref;
            });
        };
        fetchUserData();
        return () => {
            // Clean up the subscription
            unsubscribeFirebaseRef && unsubscribeFirebaseRef();
        };
    }, []);
    return { state, dispatch };
}
