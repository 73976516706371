import React, { useState, useEffect, useContext, createContext } from "react";
import Firebase from "../firebase";

const authContext = createContext(null);

/**
 * Provider component that wraps your app and makes auth object available to any child component that calls useAuth().
 */
export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

/**
 * Hook for child components to get the auth object and re-render when it changes.
 * @returns {AuthContext}
 */
export const useAuth = () => {
    return useContext(authContext);
};

/**
 * Provider hook that creates auth object and handles state
 * @returns {AuthContext}
 */
function useProvideAuth() {
    const [user, setUser] = useState(null);

    const signinWithEmail = (email, password) => {
        return Firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(response => {
                setUser(response.user);
                return response.user;
            });
    };

    const signupWithEmail = (email, password) => {
        return Firebase
            .auth()
            .createUserWithEmailAndPassword(email, password)
            .then(response => {
                setUser(response.user);
                return response.user;
            });
    };

    // Wrap any Firebase methods we want to use making sure to save the user to state.
    const signinWithPhoneNo = ({ phoneNo }) => {
        var appVerifier = window['recaptchaVerifier'];
        return Firebase.auth().signInWithPhoneNumber(phoneNo, appVerifier)
            .then(function (confirmationResult) {
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                return confirmationResult;
            }).catch(function (error) {
                window['recaptchaVerifier'].render().then(widgetId => {
                    window['recaptchaVerifier'].reset(widgetId);
                });
                return null;
            });
    }

    const signout = () => {
        return Firebase
            .auth()
            .signOut()
            .then(() => {
                setUser(false);
            });
    };

    const sendPasswordResetEmail = email => {
        return Firebase
            .auth()
            .sendPasswordResetEmail(email)
            .then(() => {
                return true;
            });
    };

    const confirmPasswordReset = (code, password) => {
        return Firebase
            .auth()
            .confirmPasswordReset(code, password)
            .then(() => {
                return true;
            });
    };

    const confirmPhoneSigninCode = ({ confirmationResult, code }) => {
        const credential = Firebase.auth.PhoneAuthProvider.credential(confirmationResult.verificationId, code);
        return Firebase.auth().signInWithCredential(credential);
    };

    /** Subscribe to user on mount
     * Because this sets state in the callback it will cause any component that utilizes this hook
     * to re-render with the latest auth object. */
    useEffect(() => {
        const unsubscribe = Firebase.auth().onAuthStateChanged(user => {
            if (user) {
                setUser(user);
            } else {
                setUser(false);
            }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);

    // Return the user object and auth methods
    return {
        user,
        signinWithPhoneNo,
        signinWithEmail,
        signupWithEmail,
        signout,
        sendPasswordResetEmail,
        confirmPasswordReset,
        confirmPhoneSigninCode
    };
}

/**
 * Auth Context
 * @typedef {Object} AuthContext
 * @property {firebase.User | null | boolean} user - Authenticated user
 * @property {function({phoneNo: string}): (Promise<any>) | null} signinWithPhoneNo - Signin with firebase phone no
 * @property {function({phoneNo: string}): (Promise<any>) | null} signinWithEmail - Signin with firebase phone no
 * @property {function({phoneNo: string}): (Promise<any>) | null} signupWithEmail - Signin with firebase phone no
 * @property {function({phoneNo: string}): (Promise<any>) | null} signout - Signin with firebase phone no
 * @property {function({phoneNo: string}): (Promise<any>) | null} sendPasswordResetEmail - Signin with firebase phone no
 * @property {function({phoneNo: string}): (Promise<any>) | null} confirmPasswordReset - Signin with firebase phone no
 * @property {function({confirmationResult: *, code: *}): (Promise<firebase.auth.UserCredential>)} confirmPhoneSigninCode - Signin with firebase phone no
 */