import { SaveOutlined } from '@ant-design/icons';
import { Button, Card, InputNumber, Select, Spin, Tag } from 'antd';
import React, { Component } from 'react';
import copyTextToClipboard from '../../utils/copy-to-clipboard';
import { fetchCollectionData } from '../../utils/data';
import { firebaseFns } from '../../utils/firebase';
const sampleSize = require('lodash.samplesize');

class TagManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            tagsList: [],
            currentTagsList: [],
            randomTagsList: [],
            ignoredTagsList: [],
            randomTagsNo: 6
        }
    }

    componentDidMount() {
        this.unsubscribeFirebaseRef  = fetchCollectionData({collection: 'instatags', listener: this.onTagData})
    }

    componentWillUnmount() {
        this.unsubscribeFirebaseRef && this.unsubscribeFirebaseRef();
    }

    onTagData = ({data, collection, timestamp}) => {
        const tagsListNames = this.getServerTagNameList({serverTags: data || []});
        const tagsList = this.getServerTagDocsList({serverTags: data || []});
        const currentTagsList = [...new Set(this.state.currentTagsList.concat(tagsListNames))];
        this.setState({
            loading: false,
            [collection]: data,
            tagsList,
            currentTagsList,
            serverTimestamp: timestamp
        })
    }

    getServerTagNameList({serverTags}) {
        return serverTags.map((d) => {
            return d.data.name
        });
    }

    getServerTagDocsList({serverTags}) {
        return serverTags.map((d) => {
            return {
                ...d.data,
                ref: d.ref
            }
        });
    }

    handleChange = (selectedTags) => {
        this.setState({
            currentTagsList: selectedTags
        });
    }

    handleIgnoreChange = (ignoredTags) => {
        this.setState({
            ignoredTagsList: ignoredTags
        });
    }

    handleSave() {
        const deletions = this.state.tagsList.filter((d) => {
            return !this.state.currentTagsList.includes(d.name)
        });
        const additions = this.state.currentTagsList.filter((d) => {
            return !this.state.tagsList.find(e => e.name === d)
        });
        this.addTagsToFirebase({
            additions, deletions
        });
    }

    async addTagsToFirebase({ additions, deletions }) {
        const {FirestoreDb, Firestore} = firebaseFns();
        const WriteBatch = FirestoreDb.batch();
        const tagsCollectionRef = FirestoreDb.collection('instatags');
        const timestamp = Firestore.Timestamp.now();
        additions.forEach((d) => {
            WriteBatch.set(tagsCollectionRef.doc(), {
                name: d,
                createdAt: timestamp,
                lastModifiedAt: timestamp
            });
        })
        deletions.forEach((d) => {
            WriteBatch.delete(FirestoreDb.doc(d.ref));
        })
        await WriteBatch.commit();
        return;
    }

    tagScreen() {
        const options = this.state.currentTagsList.map((d) => {
            return {
                label: d,
                value: d
            };
        });
        const extraTags = (options.length - 1);
        const moreTagsCount = (extraTags > 0) ? `...and ${extraTags} more` : '';
        if (!this.state.loading) {
            return (
                <Select
                    maxTagCount={1}
                    maxTagPlaceholder={moreTagsCount}
                    mode="tags"
                    options={options}
                    defaultValue={options.map(d => d.value)}
                    style={{ width: '100%' }}
                    placeholder="Tags"
                    onChange={this.handleChange}

                />
            );
        }
    }

    renderTagSelector() {
        const options = this.state.currentTagsList.map((d) => {
            return {
                label: d,
                value: d
            };
        });
        const extraTags = (options.length - 1);
        const moreTagsCount = (extraTags > 0) ? `...and ${extraTags} more` : '';
        return (
            <div style={{ padding: '20px', display: 'flex', alignItems: 'center' }}>
                <Card title="All tag List" style={{ width: '100%' }} size={'small'}>
                    <Select
                        maxTagCount={1}
                        maxTagPlaceholder={moreTagsCount}
                        mode="tags"
                        options={options}
                        defaultValue={options.map(d => d.value)}
                        style={{ width: '100%' }}
                        placeholder="Tags"
                        onChange={this.handleChange}

                    />
                </Card>

            </div>
        )
    }

    renderIgnoreList() {
        const options = this.state.currentTagsList.map((d) => {
            return {
                label: d,
                value: d
            };
        });
        const extraTags = (this.state.ignoredTagsList.length - 1);
        const moreTagsCount = (extraTags  > 0) ? `...and ${extraTags} more` : '';
        return (
            <div style={{ padding: '0 20px 0 20px', display: 'flex', alignItems: 'center' }}>
                <Card title="Ignored tags" style={{ width: '100%' }} size={'small'}>
                    <Select
                        maxTagCount={1}
                        maxTagPlaceholder={moreTagsCount}
                        mode="tags"
                        allowClear
                        onClear={(e) => {
                            this.setState({
                                ignoredTagsList: []
                            })
                        }}
                        options={options}
                        defaultValue={[]}
                        style={{ width: '100%' }}
                        placeholder="Ignored tags"
                        onChange={this.handleIgnoreChange}

                    />
                </Card>

            </div>
        )
    }

    updateRandomTags() {
        const tagsList = this.state.currentTagsList;
        const ignoredList = this.state.ignoredTagsList;
        const availableList = tagsList.filter((d) => {
            return !ignoredList.includes(d);
        })
        let randomTagsNo = this.state.randomTagsNo;
        if (randomTagsNo > availableList.length) {
            randomTagsNo = availableList.length;
        }
        const randomTagsList = sampleSize(availableList, randomTagsNo)
        randomTagsList.forEach((d) => {
            return (d.startsWith('#') ? d : `#${d}`);
        })
        this.setState({
            randomTagsList
        })
    }

    copyRandomTags() {
        const randomTagsCopy = (this.state.randomTagsList && this.state.randomTagsList.join(' ')) || '';
        copyTextToClipboard(randomTagsCopy)
    }

    renderRandomTags() {
        const tagsList = this.state.randomTagsList.map((d) => {
            return (
                <Tag key={d}>{d}</Tag>
            )
        })
        return (
            <div>
                {tagsList}
                <Button type="primary" onClick={e => this.copyRandomTags(e)}> Copy </Button>
            </div>
        )
    }

    handleRandomNumberChange = (value) => {
        this.setState({
            randomTagsNo: value
        });
    }

    unsavedChanges() {
        const deletions = this.state.tagsList.filter((d) => {
            return !this.state.currentTagsList.includes(d.name)
        });
        const additions = this.state.currentTagsList.filter((d) => {
            return !this.state.tagsList.find(e => e.name === d)
        });
        return {
            additions, deletions
        };
    }

    renderRandomGenerator() {
        return (
            <div style={{ padding: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {/* <Button icon={<SaveOutlined />} type="primary" onClick={e => this.handleSave(e)} disabled={disabled}> {label} </Button> */}
                <InputNumber defaultValue={this.state.randomTagsNo} onChange={this.handleRandomNumberChange} style={{marginRight: '12px', marginLeft: '12px'}} />
                <Button type="primary" onClick={e => this.updateRandomTags(e)}> Get random tags </Button>
            </div>
        )
    }

    renderChangesCard() {
        const {additions, deletions} = this.unsavedChanges();
        const changedTags = [];
        additions.forEach((d) => {
            changedTags.push((
                <Tag key={d} color="green">{d}</Tag>
            ));
        })
        deletions.forEach((d) => {
            changedTags.push((
                <Tag key={d.name} color="red">{d.name}</Tag>
            ));
        })

        if (changedTags.length === 0) {
            return null;
        }

        return (
            <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Card title="Changed tags, save changes or refresh to reset" style={{ width: '100%' }} size={'small'}>
                    {changedTags}
                </Card>
                <Button icon={<SaveOutlined />} type="primary" onClick={e => this.handleSave(e)} style={{marginTop: '12px'}}> Save changes </Button>
            </div>
        )
    }

    renderRandomTagsView() {

        const tagsList = this.state.randomTagsList.map((d) => {
            return (
                <Tag key={d}>{d}</Tag>
            )
        })

        return (
            <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Card title="Random tags" style={{ width: '100%' }} size={'small'}>
                    {tagsList}
                </Card>
                <Button type="primary" onClick={e => this.copyRandomTags(e)} style={{width: '120px', marginTop: '12px'}}> Copy </Button>
            </div>
        )
    }

    render() {
        if (this.state.loading) {
            return (
                <div style={{
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <Spin style={{margin: 'auto'}} />
                </div>
            )
        }
        return (
            <>
                {this.renderTagSelector()}
                {this.renderChangesCard()}
                {this.renderIgnoreList()}
                {this.renderRandomGenerator()}
                {this.renderRandomTagsView()}
            </>
        )
    }
}

export default TagManager;