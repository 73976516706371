import { ProvideAuth } from "../utils/hooks/use-auth";
import { ProvideMedia } from "../utils/hooks/use-media";
import React from 'react';

export function AppProvider({children}) {
    return (
        <ProvideMedia>
            <ProvideAuth>
                {children}
            </ProvideAuth>
        </ProvideMedia>
    )
}