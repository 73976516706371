import { DeleteOutlined, DeleteRowOutlined, EditOutlined, MessageOutlined, PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { Collapse, Input, Select, Spin, Switch, Table, Tag, Button} from 'antd';
import { Tabs } from 'antd-mobile';
import Avatar from 'antd/lib/avatar/avatar';
import { format, parse } from 'date-fns';
import React, { useCallback, useMemo, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import {  getValidDate } from '../../utils/data';
import { AvailableClientLabels } from '../../utils/helpers';
import BackButton from '../common/BackButton';
import DatePicker from '../DatePicker';
import './ClientHome.css';
import MealIcon from '../../images/icons/meal-icon.png';
import WorkoutIcon from '../../images/icons/workout-icon.png';
import CameraIcon from '../../images/icons/camera-icon.png';
import PhoneIcon from '../../images/icons/call-icon.png';
import MeasurementIcon from '../../images/icons/measurement-icon.png';
import { useFirebaseData } from '../../utils/hooks/use-firebase';
import { useLayout } from '../../utils/hooks/use-media';
import { deleteReminders, generateReminders } from '../../services/reminders';

const { Panel } = Collapse

const EditRedColor = '#c82021';

function tagRender(props, options) {
    const { label, value, closable, onClose } = props;
    const currLabel = options.find(d => d.value === value);
    const color = options ? (options.find(d => d.value === value))?.color : '';
    if (currLabel) {
        return (
            <Tag color={color} closable={closable} onClose={onClose} style={{ marginRight: 3, fontWeight: 'bold' }}>
                {label}
            </Tag>
        );
    }
    return null;
}

const ClientAvatar = ({ profilePic, name }) => {
    const firstLetter = name?.[0] || 'U';
    if (profilePic) {
        return (
            <Avatar src={profilePic} style={{
                borderRadius: '50%',
                width: '100%',
                height: '100%',
                overflow: 'hidden'
            }} />
        )
    } else {
        return (
            <Avatar
                style={{
                    backgroundColor: '#f56a00',
                    verticalAlign: 'middle',
                }}
                size="large"
            >
                {firstLetter}
            </Avatar>
        );
    }
};

const FlexDiv = ({ children, direction = 'row', alignItems = "normal" }) => {
    return (
        <div style={{ display: 'flex', flexDirection: direction, alignItems }}>
            {children}
        </div>
    )
}

const CommonTextArea = ({ content, disabled=false, style={}, placeholder, onChange}) => {

    return useMemo(() => {
        return (
            <Input.TextArea
                disabled={disabled}
                placeholder={placeholder || '...'}
                defaultValue={content}
                value={content}
                bordered={false}
                onChange={(e) => {
                    const val = e?.target?.value || '';
                    onChange(val);
                }}
                style={style}
                autoSize={{ minRows: 1, maxRows: 4 }}
            />
        )
    }, [content, disabled, style, placeholder, onChange]);
}

const CommonInput = ({val, placeholder, style={}, onChange, autoFocus=false}) => {
    return (
        <Input
            style={style}
            bordered={false}
            defaultValue={val || ''}
            value={val || ''}
            placeholder={placeholder || 'Add...'}
            autoFocus={autoFocus}
            onChange={(e) => {
                const value = e?.target?.value;
                onChange(value);
            }}
        />
    );
}

const EditIcon = ({ isEditing = false, onClick, disabled = false}) => {
    const iconStyle = isEditing ? {color: EditRedColor} : {};
    return (
        <span style={{ padding: '0 12px', cursor: 'pointer' }} onClick={e => onClick('edit', e)}>
            <EditOutlined style={{ fontSize: '24px', marginTop: '5px', ...iconStyle }} disabled />
        </span>
    )
}

const ChatIcon = ({ onClick = () => {} }) => {
    return (
        <span style={{ padding: '0 12px', cursor: 'pointer' }} onClick={e => onClick(e)}>
            <MessageOutlined style={{ fontSize: '24px', marginTop: '5px' }} />
        </span>
    );
}

const ClientHeader = () => {
    const history = useHistory();
    const layout = useLayout();
    const { client, setIsEditing, isEditing } = useClientData();

    const { name = 'Unnamed', profilePic } = client.data || {};

    return (
        <div className='gh-portal-content-header' style={{
            padding: '10px 16px', height: '60px', background: '#ffc9e7', display: 'flex', alignItems: 'center'
        }}>
            {
                layout === 'desktop' ? null : <BackButton onClick={() => {
                    history.replace('/');
                }} />
            }
            <ClientAvatar profilePic={profilePic} name={name} />
            <div style={{ flexGrow: 1 }}>
                <FlexDiv alignItems='center'>
                    <h3 style={{ flexGrow: 1, fontSize: '16px', marginLeft: '12px' }}>{name}</h3>
                    <EditIcon isEditing={isEditing} onClick={(type, e) => {
                        setIsEditing(!isEditing);
                    }} />
                    <SaveSpinner />
                    <ChatIcon />
                </FlexDiv>
            </div>
        </div>
    )
};

const SaveSpinner = () => {
    const {isSaving} = useClientData();
    const spin = isSaving;
    const color = isSaving ? { color: 'red' } : { color: 'green' };
    const antIcon = <SyncOutlined style={{ fontSize: 24, ...color }} spin={spin} />;
    return (
        <div style={{ marginTop: '5px', padding: '0 5px'}}>
            <Spin indicator={antIcon} />
        </div>
    );
}

const ClientLabel = () => {
    const { client: user, dispatchUpdateUser} = useClientData();
    const client = user.data;
    const labels = AvailableClientLabels;

    function tagRender(props) {
        const { label, value, closable, onClose } = props;
        const currLabel = labels.find(d => d.value === value);
        return (
            <Tag color={currLabel.color} closable={closable} onClose={onClose} style={{ marginRight: 3, fontWeight: 'bold' }}>
                {label}
            </Tag>
        );
    }

    return (
        <Collapse bordered={false} defaultActiveKey={['1']}>
            <Panel header={<PanelHeader title='Labels' />} key="1">
                <section className='gh-portal-account-data' style={{ marginBottom: '3px' }}>
                    <div className='gh-portal-list'>
                        <Select
                            tagRender={tagRender}
                            mode="tags"
                            allowClear
                            onClear={(e) => {}}
                            options={labels}
                            defaultValue={[]}
                            style={{ width: '100%' }}
                            value={client.labels}
                            placeholder="Labels..."
                            onChange={(values) => {
                                user.updateClientData({labels: values});
                                dispatchUpdateUser(user);
                            }}
                        />
                    </div>
                </section>
            </Panel>
        </Collapse>
    );
}

const DateInfoCell = ({data}) => {
    const { dispatchUpdateUser } = useClientData();

    const val = data.content;
    const dateObj = val ? parse(val, 'dd/MM/yy', new Date()) : null;
    return (
        <DatePicker
            showToday
            onChange={(date) => {
                const updatedUser = data.updater?.(date ? format(date, 'dd/MM/yy') : '');
                dispatchUpdateUser(updatedUser);
            }}
            style={{ padding: 0 }}
            format='DD MMM'
            bordered={false}
            allowClear={true}
            placeholder={'Date'}
            suffixIcon={null}
            defaultValue={dateObj}
            value={dateObj}
        />
    );
};

const FoodPrefCell = ({data}) => {
    const val = data.content || '';
    const { dispatchUpdateUser, isEditing } = useClientData();
    const disabled = (!isEditing);
    const options = [
        {
            label: 'Non-Veg',
            value: 'nonveg'
        },
        {
            label: 'Veg',
            value: 'veg'
        },
        {
            label: 'Egg',
            value: 'egg'
        },
        {
            label: 'Select',
            value: ''
        }
    ]
    return (
        <Select
            allowClear
            disabled={disabled}
            bordered={false}
            onClear={(e) => { }}
            options={options}
            size='small'
            defaultValue={[]}
            style={{ width: '100%' }}
            value={val}
            placeholder="Food Pref."
            onChange={(value) => {
                const updatedUser = data.updater?.(value);
                dispatchUpdateUser(updatedUser);
            }}
        />
    );
}

const InfoCellContent = ({data}) => {
    const { client: user, dispatchUpdateUser, isEditing} = useClientData();
    const disabled = (!isEditing);
    if (data.type === 'date') {
        return (
            <DateInfoCell data={data} />
        );
    }

    if (data.name === 'foodPref') {
        return (
            <FoodPrefCell data={data} />
        );
    }

    /**
     * @type {React.CSSProperties}
     */
    const extraStyle = ['why', 'touchpoints'].includes(data.name) ? {
        fontWeight: 'bold',
        fontSize: '1.4rem'
    } : {};
    return (
        <CommonTextArea
            content={data.content}
            placeholder={data.placeholder || 'Add..'}
            style={extraStyle}
            disabled={disabled}
            onChange={(val) => {
                const updatedUser = data.updater?.(val) || user.updateNotes({ [data.name]: val });
                dispatchUpdateUser(updatedUser);
            }}
        />
    );
}

const InfoListRow = ({data}) => {
    const rows = data.map((d, idx) => {
        return (
            <div
                className={`gh-portal-list-detail info${idx === 0 ? ' first' : ''}`}
                key={d.heading}
            >
                <h3>{d.heading}</h3>
                <InfoCellContent data={d} />
            </div>
        )
    })
    return (
        <section>
            {rows}
        </section>
    )
}

const PlanUpdateTable = () => {
    const {client: user, dispatchUpdateUser, isEditing} = useClientData();
    const client = user.data;
    const disabled = !isEditing;
    /**
     * @type {import('antd/lib/table').ColumnsType<any>}
     */
    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            width: 70,
            className: 'editable-input',
            render: (val, record) => {
                const dateObj = val ? parse(val, 'dd/MM/yy', new Date()) : null;
                return (
                    <DatePicker
                        showToday
                        onChange={(date, datestr) => {
                            user.updatePlanUpdateRow(record, {
                                'date': format(date, 'dd/MM/yy')
                            });
                            dispatchUpdateUser(user);
                        }}
                        disabled={disabled}
                        format='DD MMM'
                        bordered={false}
                        allowClear={false}
                        placeholder={'Date'}
                        suffixIcon={null}
                        defaultValue={dateObj}
                        value={dateObj}
                    />
                );
            },
        },
        {
            title: 'Type',
            dataIndex: 'type',
            width: 130,
            className: 'editable-input',
            render: (val, record) => {
                const options = [
                    {
                        label: 'Call',
                        value: 'call',
                        color: '#8a6e00'
                    },
                    {
                        label: 'Workout',
                        value: 'workout',
                        color: '#4c5f03'
                    },
                    {
                        label: 'Meal',
                        value: 'meal',
                        color: '#e8161b'
                    },
                    {
                        label: 'Pics',
                        value: 'pic',
                        color: '#0a98c5'
                    },
                    {
                        label: 'Mments',
                        value: 'measurement',
                        color: '#05ab90'
                    },
                ];
                return (
                    <Select
                        tagRender={(props) => {
                            return tagRender(props, options);
                        }}
                        mode="tags"
                        allowClear
                        onClear={(e) => { }}
                        options={options}
                        defaultValue={[]}
                        style={{ width: '100%' }}
                        value={val ? [val] : []}
                        placeholder="Type"
                        onChange={(values) => {
                            const length = values.length;
                            user.updatePlanUpdateRow(record, {
                                'type': values?.[length - 1] || ''
                            });
                            dispatchUpdateUser(user);
                        }}
                    />
                );
            },
        },
        {
            title: 'Note',
            dataIndex: 'note',
            className: 'editable-input',
            render: (val, record) => {
                return (
                    <CommonInput
                        val={val}
                        placeholder='Reason'
                        onChange={(value) => {
                            user.updatePlanUpdateRow(record, {
                                'note': value
                            });
                            dispatchUpdateUser(user);
                        }}
                    />
                );
            },
        },
    ];
    if (isEditing) {
        columns.push({
            title: () => {
                return (
                    <DeleteRowOutlined style={{ color: 'red' }} />
                )
            },
            dataIndex: 'delete',
            className: 'editable-input',
            render: (val, record) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <DeleteOutlined
                            style={{ fontSize: '14px', color: 'red' }}
                            onClick={(e) => {
                                user.deletePlanUpdateRow(record);
                                dispatchUpdateUser(user);
                            }}
                        />
                    </div>
                );
            },
        })
    }
    let planUpdatesData = (client?.planUpdates || []).map((d) => {
        return { ...d, key: d.uuid }
    });

    planUpdatesData.sort((a, b) => {
        return getValidDate(b.date, 'dd/MM/yy').getTime() - getValidDate(a.date, 'dd/MM/yy').getTime();
    });
    return (
        <Table rowKey={record => record.uuid} columns={columns} dataSource={planUpdatesData} pagination={false} bordered size='small' scroll={{ x: '100%' }} />
    )
};

const ProgressionTable = () => {
    const { client: user, dispatchUpdateUser, isEditing} = useClientData();
    const client = user.data;
    const disabled = (!isEditing);
    /**
     * @type import('antd/lib/table').ColumnsType<any>
     */
    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            width: 70,
            className: 'editable-input',
            render: (val, record) => {
                const dateObj = val ? parse(val, 'dd/MM/yy', new Date()) : null;
                return (
                    <DatePicker
                        showToday
                        onChange={(date, datestr) => {
                            user.updateProgressionRow(record, {
                                'date': format(date, 'dd/MM/yy')
                            });
                            dispatchUpdateUser(user);
                        }}
                        disabled={disabled}
                        format='DD MMM'
                        bordered={false}
                        allowClear={false}
                        placeholder={'Date'}
                        suffixIcon={null}
                        defaultValue={dateObj}
                        value={dateObj}
                    />
                );
            },
        },
        {
            title: 'Wt',
            dataIndex: 'weight',
            width: 60,
            className: 'editable-input',
            render: (val, record) => {
                return (
                    <CommonInput
                        style={{ color: 'black', fontWeight: 'bold' }}
                        val={val}
                        placeholder=''
                        autoFocus={!val}
                        onChange={(value) => {
                            user.updateProgressionRow(record, {
                                'weight': value
                            });
                            dispatchUpdateUser(user);
                        }}
                    />
                );
            },
        },
        {
            title: 'Note',
            dataIndex: 'note',
            className: 'editable-input',
            render: (val, record) => {
                return (
                    <CommonInput
                        style={{ color: 'black', fontWeight: 'bold' }}
                        val={val}
                        placeholder=''
                        onChange={(value) => {
                            user.updateProgressionRow(record, {
                                'note': value
                            });
                            dispatchUpdateUser(user);
                        }}
                    />
                );
            },
        },
    ];
    if (isEditing) {
        columns.push({
            title: () => {
                return (
                    <DeleteRowOutlined style={{color: 'red'}} />
                )
            },
            dataIndex: 'delete',
            className: 'editable-input',
            render: (val, record) => {
                return (
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <DeleteOutlined
                            style={{ fontSize: '14px', color: 'red' }}
                            onClick={(e) => {
                                user.deleteProgressionRow(record);
                                dispatchUpdateUser(user);
                            }}
                        />
                    </div>
                );
            },
        })
    }
    let progressionData = (client?.progression || []).map((d) => {
        return {...d, key: d.uuid}
    });
    progressionData.sort((a, b) => {
        return getValidDate(b.date, 'dd/MM/yy').getTime() - getValidDate(a.date, 'dd/MM/yy').getTime();
    });
    if (!isEditing) {
        progressionData.sort((a, b) => {
            return getValidDate(b.date, 'dd/MM/yy').getTime() - getValidDate(a.date, 'dd/MM/yy').getTime();
        });
    }
    return (
        <Table rowKey={record => record.uuid} columns={columns} dataSource={progressionData} pagination={false} bordered size='small' scroll={{ x: '100%' }} />
    )
};

const PanelHeader = ({title}) => {
    const {isEditing} = useClientData();
    const extraStyle = isEditing ? {color: 'grey'} : {};
    return (
        <span style={{ ...extraStyle}}> {title} </span>
    );
};


const AllInfoTab = () => {
    const {client: user} = useClientData();
    const client = user.data;
    const {
        touchpoints = '',
        postpartum = '',
        why = ''
    } = client?.notes || {};
    const isPlanUpdateExpanded = client?.planUpdates?.length > 0
    return (
        <div className='gh-portal-content fullscreen grey' style={{
            paddingLeft: '12px', paddingRight: '12px', paddingTop: '12px',
            boxShadow: 'inset 0 0 3px #ffd0d0'
        }}>
            <Collapse bordered={false} defaultActiveKey={['1']} collapsible={'header'}>
                <Panel header={<PanelHeader title='Highlights' />} key="1">
                    <section className='gh-portal-account-data' style={{ marginBottom: '3px' }}>
                        <div className='gh-portal-list'>
                            <InfoListRow data={[
                                {
                                    name: 'postpartum',
                                    heading: 'PP',
                                    content: postpartum
                                },
                                {
                                    name: 'touchpoints',
                                    heading: 'Touch points',
                                    content: touchpoints
                                }
                            ]} />
                            <InfoListRow data={[
                                {
                                    name: 'why',
                                    heading: 'Why',
                                    content: why
                                }
                            ]} />
                        </div>
                    </section>
                </Panel>
            </Collapse>
            <Collapse bordered={false} activeKey={['1']} collapsible={'header'}>
                <Panel header={<PanelHeader title='Progression' />} key="1" extra={(
                    <AddProgressionRow />
                )}>
                    <ProgressionTable />
                </Panel>
            </Collapse>
            <Collapse
                bordered={false}
                activeKey={isPlanUpdateExpanded ? ['1'] : []}
                style={{ marginBottom: '12px' }}
                key={`${client.uuid}${client?.planUpdates?.length > 0}`}
            >
                <Panel header={<AddPlanUpdatesRow />} key="1">
                    <PlanUpdateTable />
                </Panel>
            </Collapse>
        </div>
    );
}

const AddProgressionRow = () => {
    const { client: user, isEditing, dispatchUpdateUser} = useClientData();
    if (isEditing) {
        return (
            <PlusOutlined
                style={{color: 'green', fontWeight: 'bold', fontSize: '18px'}}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    user.addProgressionRow();
                    dispatchUpdateUser(user);
                }}
            />
        );
    }
    return null;
};

const AddPlanUpdatesRow = () => {
    const {isEditing} = useClientData();
    if (isEditing) {
        return (
            <div style={{display: 'flex'}}>
                <div> Updates </div>
                <div style={{flexGrow: 1, display: 'flex', justifyContent: 'space-between', marginLeft: '12px'}}>
                    <PlanUpdateOption icon={MealIcon} type='meal' />
                    <PlanUpdateOption icon={WorkoutIcon} type='workout' />
                    <PlanUpdateOption icon={PhoneIcon} type='call' />
                    <PlanUpdateOption icon={CameraIcon} type='pic' />
                    <PlanUpdateOption icon={MeasurementIcon} type='measurement' />
                </div>
            </div>
        );
    }
    return null;
};

const PlanUpdateOption = ({icon, type }) => {
    const imgStyle = {
        height: '18px'
    }
    const { dispatchUpdateUser, client: user } = useClientData();

    const addPlanUpdate = useCallback((e, type) => {
        e.stopPropagation();
        e.preventDefault();
        user.addPlanUpdateRow({ type })
        dispatchUpdateUser(user);
    }, [dispatchUpdateUser, user]);
    return (
        <div style={{ width: '44px', textAlign: "center" }} onClick={(e) => addPlanUpdate(e, type)}>
            <img src={icon} alt='meal-icon' style={imgStyle} />
        </div>
    )
}

const BackendInfoTab = () => {
    return (
        <div
            className='gh-portal-content fullscreen grey'
            style={{ paddingLeft: '12px', paddingRight: '12px', paddingTop: '12px' }}
        >
            <ClientLabel />
            <Collapse bordered={false} defaultActiveKey={['1']} collapsible="header">
                <Panel header={<PanelHeader title='Highlights' />} key="1">
                    <section className='gh-portal-account-data' style={{ marginBottom: '0' }}>
                        <HighlightsEditSection />
                    </section>
                </Panel>
            </Collapse>
            <Collapse bordered={false} defaultActiveKey={['1']} collapsible="header">
                <Panel header={<PanelHeader title='Plan' />} key="1">
                    <section className='gh-portal-account-data' style={{ marginBottom: '0' }}>
                        <PlanEditSection />
                    </section>
                </Panel>
            </Collapse>
            <Collapse bordered={false} defaultActiveKey={['1']} collapsible="header">
                <Panel header={<PanelHeader title='Profile' />} key="1">
                    <section className='gh-portal-account-data' style={{ marginBottom: '0' }}>
                        <ProfileEditSection />
                    </section>
                </Panel>
            </Collapse>
        </div>
    );
}

const ProfileEditSection = () => {
    const { client: user, dispatchUpdateUser } = useClientData();

    const client = user.data;
    const name = client.name;
    const email = client.email;
    const phone = client.phone;
    const instagram = client.instagram;
    const isDisabled = !!client.isDisabled;
    const isRemindersDisabled = !!client.isRemindersDisabled;

    return (
        <div className='gh-portal-list'>
            <InfoListRow data={[
                {
                    name: 'name',
                    heading: 'Name',
                    content: name,
                    updater: (val = '') => {
                        user.updateClientData({ name: val });
                        return user;
                    }
                }
            ]} />
            <InfoListRow data={[
                {
                    name: 'email',
                    heading: 'Email',
                    content: email,
                    updater: (val = '') => {
                        user.updateClientData({ email: val });
                        return user;
                    }
                },
                {
                    email: 'phone',
                    heading: 'Phone',
                    content: phone,
                    updater: (val = '') => {
                        user.updateClientData({ phone: val });
                        return user;
                    }
                }
            ]} />
            <InfoListRow data={[
                {
                    name: 'instagram',
                    heading: 'Instagram',
                    content: instagram,
                    updater: (val = '') => {
                        user.updateClientData({ instagram: val });
                        return user;
                    }
                }
            ]} />
            <div style={{ display: 'flex', marginTop: '12px' }}>
                <div style={{ paddingRight: '12px' }}> Disable Client </div>
                <Switch checked={isDisabled} onChange={(checked) => {
                    user.updateClientData({ isDisabled: checked });
                    dispatchUpdateUser(user);
                }} />
            </div>
            <div style={{ display: 'flex', marginTop: '12px' }}>
                <div style={{ paddingRight: '12px' }}> Disable Reminders </div>
                <Switch checked={isRemindersDisabled} onChange={(checked) => {
                    user.updateClientData({ isRemindersDisabled: checked });
                    dispatchUpdateUser(user);
                }} />
            </div>
            <div style={{ display: 'flex', marginTop: '12px' }}>
                <Button onClick={async () => {
                    await deleteReminders(user.data);
                    await generateReminders(user.data);
                }}>
                    Reset Reminders
                </Button>
            </div>
        </div>
    );
}

const HighlightsEditSection = () => {
        const {client: user} = useClientData();

    const client = user.data;
    const {
        age = '',
        height = '',
        info = '',
        food = '',
        foodPref = ''
    } = client?.notes || {};
    const bmr = user.bmr || '';

    let ageVal = age || '';
    let heightVal = height ? `,${height}` : ',';
    const ageHeight = `${ageVal}${heightVal}`;

    return (
        <div className='gh-portal-list'>
            <InfoListRow data={[
                {
                    name: 'ageHeight',
                    heading: 'Age / Height',
                    content: ageHeight,
                    updater: (val = '') => {
                        const [age = '', height = ''] = val.split(',');
                        user.updateNotes({ age: age.trim(), height: height.trim() });
                        return user;
                    }
                },
                {
                    name: 'bmr',
                    heading: 'BMR / TDEEE',
                    content: bmr
                },
                {
                    name: 'foodPref',
                    heading: 'Food Pref.',
                    content: foodPref,
                    updater: (val = '') => {
                        user.updateNotes({ foodPref: val });
                        return user;
                    }
                }
            ]} />
            <InfoListRow data={[
                {
                    name: 'info',
                    heading: 'Info',
                    content: info
                },
                {
                    name: 'food',
                    heading: 'Food',
                    content: food
                }
            ]} />
        </div>
    )
}

const PlanEditSection = () => {
    const { client: user } = useClientData();

    const client = user.data;
    const { type: clientType } = client;
    const {
        amount: planAmount = '',
        months: planMonths = '',
        reference = '',
        startDate = '',
    } = client?.plan || {};
    return (
        <div className='gh-portal-list'>
            <InfoListRow data={[
                {
                    name: 'startDate',
                    type: 'date',
                    heading: 'Start Date',
                    content: startDate,
                    updater: (val) => {
                        user.updatePlan({ startDate: val.trim() });
                        return user;
                    }
                },
                {
                    name: 'planMonths',
                    heading: 'Months',
                    content: planMonths,
                    updater: (val) => {
                        user.updatePlan({ months: val.trim() });
                        return user;
                    }
                }
            ]} />
            <InfoListRow data={[
                {
                    name: 'planAmount',
                    heading: 'Amount',
                    content: planAmount,
                    updater: (val) => {
                        user.updatePlan({ amount: val.trim() });
                        return user;
                    }
                },
                {
                    name: 'type',
                    heading: 'Type',
                    content: clientType,
                    updater: (val) => {
                        user.updateClientData({ type: val.trim() });
                        return user;
                    }
                },
                {
                    name: 'reference',
                    heading: 'Ref.',
                    content: reference,
                    updater: (val) => {
                        user.updatePlan({ reference: val.trim() });
                        return user;
                    }
                }
            ]} />
        </div>
    )
}

const ClientScreen = () => {
    const TABS = [
        {title: 'All'},
        {title: 'Backend'}
    ]
    return (
        <Tabs tabs={TABS}
            initialPage={0}
            tabBarPosition="bottom"
            renderTab={tab => <span>{tab.title}</span>}
        >
            <>
                <AllInfoTab />
            </>
            <>
                <BackendInfoTab />
            </>
        </Tabs>
    );
};

const useClientData = () => {
    const {dispatch, status, data, isSaving} = useFirebaseData();
    const [isEditing, setIsEditing] = useState(true);
    const dispatchUpdateUser = useCallback(
        (user) => {
            dispatch({ type: 'updateUser', payload: user });
        },
        [dispatch],
    );
    /**
     * @type {{uuid: string}}
     */
    const { uuid } = useParams();
    const client = data.findByUUID(uuid);
    return { client, status, isEditing, isSaving, setIsEditing, dispatchUpdateUser}
};

const NewClientHome = () => {
    const {client} = useClientData();
    if (!client) {
        return (
            <Redirect to='/' />
        )
    }

    return (
        <>
            <ClientHeader />
            <ClientScreen />
        </>
    );
};

export default NewClientHome;
