const Localforage = require('localforage');

/**
 * OfflineStorage
 *
 * @returns {LocalForage} - Local Forage instance
 */
function LocalforageInstance() {
    Localforage.dropInstance({ name:"NHApp-002"})
    Localforage.dropInstance({ name:"NHApp-003"})
    Localforage.dropInstance({ name:"NHApp-004"})

    const OfflineStore = Localforage.createInstance({
        name: "NHApp-005"
    });

    return OfflineStore;
};

const OfflineStore = LocalforageInstance();

export default OfflineStore;
