import { addDays, subDays, compareAsc, differenceInCalendarDays, format, isAfter, parse} from "date-fns";
import Fuse from "fuse.js";
import { getValidDate } from "../utils/data";
import Firebase from "../utils/firebase";
import { getUUID } from "../utils/helpers";
import { logger } from "../utils/logger";
import {sortByLatest, transformDataTimestamps, transformTimestamps} from './utils';
import parseDate from 'date-fns/parse';
import isBefore from 'date-fns/isBefore';
import { generateReminders } from "./reminders";

/**
 * @typedef UserPlanUpdateData
 * @type {Object}
 * @property {firebase.firestore.Timestamp} createdAt
 * @property {firebase.firestore.Timestamp} lastModifiedAt
 * @property {String} uuid
 * @property {String} date
 * @property {String} type
 * @property {String} note
 */


/**
 * @typedef UserProgressionData
 * @type {Object}
 * @property {firebase.firestore.Timestamp} createdAt
 * @property {firebase.firestore.Timestamp} lastModifiedAt
 * @property {String} uuid
 * @property {String} date
 * @property {String} weight
 * @property {String} note
 */

/**
 * @typedef UserPlanData
 * @type {Object}
 * @property {String} amount
 * @property {String} months
 * @property {String} startDate
 * @property {String} reference
 */

/**
* @typedef UserNotesData
* @type {Object}
* @property {String} why
* @property {String} height
* @property {String} age
* @property {String} touchpoints
* @property {String} food
* @property {String} info
* @property {String} bmr
* @property {String} postpartum
* @property {String} foodPref
*/

function isNullOrUndefined(val) {
    return (val === undefined && val === null);
}


/**
 * @typedef UserData
 * @type {Object}
 * @property {String} name
 * @property {String} uuid
 * @property {String} id
 * @property {String} type
 * @property {String} mobile
 * @property {String} profilePic
 * @property {Boolean} important
 * @property {Boolean} isDisabled
 * @property {firebase.firestore.Timestamp} createdAt
 * @property {firebase.firestore.Timestamp} lastModifiedAt
 * @property {UserPlanData} plan
 * @property {UserNotesData} notes
 * @property {UserProgressionData[]} progression
 * @property {UserPlanUpdateData[]} planUpdates
 * @property {String[]} labels
 */

function isFirebaseTimestampObject(timestamp) {
    return (
        typeof timestamp === 'object' &&
        (!isNullOrUndefined(timestamp?.seconds) || isNullOrUndefined(timestamp?._seconds)) &&
        (!isNullOrUndefined(timestamp?.nanoseconds) || !isNullOrUndefined(timestamp?._nanoseconds))
    );
}

export function serializeFirebaseTimestamp(timestamp) {
    try {
        if (timestamp instanceof Firebase.firestore.Timestamp) {
            return timestamp;
        } else if (isFirebaseTimestampObject(timestamp)) {
            const seconds = !isNullOrUndefined(timestamp.seconds) ? timestamp.seconds: timestamp._seconds;
            const nanoseconds = !isNullOrUndefined(timestamp.nanoseconds) ? timestamp.nanoseconds : timestamp._nanoseconds;
            return new Firebase.firestore.Timestamp(seconds, nanoseconds);
        } else if (!timestamp) {
            return null;
        }
        return null;
        // throw new Error("[NH] Unable to serialize Firebase timestamp, invalid object");
    } catch (e) {
        return null;
        // throw new Error("[NH] Unable to serialize Firebase timestamp, invalid object");;
    }
}

/**
 * Serializer user
 */
function serializeUser(user) {
    const data = user.data;
    user.lastModifiedAt = serializeFirebaseTimestamp(user.lastModifiedAt);
    serializeUserData(data);
    return user;
}

/**
 *
 * @param {any} data
 * @returns {UserData} Serialized User Data
 */
function serializeUserData(data) {
    data.createdAt = serializeFirebaseTimestamp(data.createdAt);
    data.lastModifiedAt = serializeFirebaseTimestamp(data.lastModifiedAt);
    data.progression.forEach(d => {
        d.createdAt = serializeFirebaseTimestamp(d.createdAt);
        d.lastModifiedAt = serializeFirebaseTimestamp(d.lastModifiedAt);
    });
    data.planUpdates.forEach(d => {
        d.createdAt = serializeFirebaseTimestamp(d.createdAt);
        d.lastModifiedAt = serializeFirebaseTimestamp(d.lastModifiedAt);
    });
    return data;
}

/**
 * Sort users by latest modified user first
 * @param {User[]} users - Users list
 * @returns {User[]} Serialized Users list
 */
export function sortUsersByLatest(users = []) {
    const Timestamp = Firebase.firestore.Timestamp;
    return [...users].sort((doc1, doc2) => {
        if (doc1?.lastModifiedAt instanceof Timestamp && doc2?.lastModifiedAt instanceof Timestamp) {
            return doc2.lastModifiedAt.toMillis() - doc1.lastModifiedAt.toMillis();
        }
        return 0;
    });
}

function getUserStartDate({ user }) {
    let startDateStr = user?.plan?.startDate || '';
    if (typeof startDateStr !== 'string') {
        return '';
    }
    let format = 'dd/MM/yy';
    if (startDateStr.split('/')?.[2]?.trim().length === 4) {
        startDateStr = startDateStr.replace('0200', '2020');
        format = 'dd/MM/yyyy';
    } else if (startDateStr.split('-')?.[1]?.trim().length > 0) {
        format = 'dd-MM/yy';
    } else if (startDateStr.split('/')?.length === 2) {
        format = 'dd/MM';
    }
    return getValidDate(startDateStr, format);
}

export async function addUserToFirebase(user) {
    const WriteBatch = Firebase.db.batch();
    const userRef = Firebase.db.doc(`users/${user.id}`);
    const writeTimestamp = Firebase.firestore.Timestamp.now();
    const newUser = {
        ...user,
        createdAt: writeTimestamp,
        lastModifiedAt: writeTimestamp
    };
    WriteBatch.set(userRef, newUser);
    await WriteBatch.commit();
    logger("User Added", userRef, newUser);
    await generateReminders(user)
    return;
}

export class UserList {
    /**
     * @param {Object} options
     * @param {User[]} options.data
     */
    constructor({data} = {data: []}) {
        this.data = data || [];
        this.updateLastModifiedAt();
    }

    get length() {
        return this.data.length;
    }

    get users() {
        if (this.data) {
            return this.data.map(d => d.data);
        }
        return [];
    }

    /**
     *
     * @param {User} user
     */
    updateUser(user) {
        this.data = this.data.map((d) => {
            if (d.uuid === user.uuid) {
                return user;
            }
            return d;
        });
        this.updateLastModifiedAt();
        return this;
    }

    /**
     *
     * @param {User} user
     */
    addUser(user) {
        this.data.push(user);
        this.updateLastModifiedAt();
        return this;
    }

    /**
     *
     * @param {User} user
     */
    removeUser(user) {
        this.data = this.data.filter((d) => {
            return d.uuid !== user?.uuid;
        })
        this.updateLastModifiedAt();
        return this;
    }

    /**
     *
     * @param {User[]} users
     */
    updateUserList(users) {
        logger("Updating user list", users);
        users.forEach((user) => {
            if (this.findByUUID(user.uuid)) {
                this.updateUser(user);
            } else {
                this.addUser(user);
            }
        });
        return this;
    }

    updateLastModifiedAt() {
        const timestamp = this.getLastModifiedUserTime();
        if (timestamp instanceof Firebase.firestore.Timestamp) {
            this.lastModifiedAt = timestamp.toDate().getTime();
        } else {
            this.lastModifiedAt = null;
        }
    }

    updateFirebaseTimestamp() {
        this.lastFirebaseUpdateTimestamp = Firebase.firestore.Timestamp.now()
    }

    /**
     *
     * @param {User[]} users
     */
    removeUsers(users) {
        const uuids = users.map(d => d.uuid);
        this.data = this.data.filter((d) => {
            return !uuids.includes(d.uuid);
        })
        this.updateLastModifiedAt();
        return this;
    }

    sortyBy(sortType) {
        const sortedUsers = this.data.sort((a, b) => {
            if (sortType === 'alphabetical') {
                return a?.name?.localeCompare(b.name);
            }
            const dateA = getUserStartDate({ user: a.data }) || new Date();
            const dateB = getUserStartDate({ user: b.data }) || new Date();
            return dateB.getTime() - dateA.getTime();
        })
        return new UserList({data: sortedUsers});
    }

    getLastModifiedUserTime() {
        const Timestamp = Firebase.firestore.Timestamp;
        const lastModifiedUser = this.data.filter((d) => {
            return (d.lastModifiedAt instanceof Timestamp);
        }).sort((doc1, doc2) => {
            return doc2.lastModifiedAt.toMillis() - doc1.lastModifiedAt.toMillis();
        })[0];
        return lastModifiedUser?.lastModifiedAt;
    }

    /**
     *
     * @param {Object} options
     * @param {String} options.searchVal
     * @returns {UserList}
     */
    filterOnSearch({searchVal}) {
        if (!this.users.length) {
            return new UserList();
        }
        if (!searchVal) {
            return this;
        }
        const fusejs = new Fuse(this.users, {
            keys: ['name'],
            includeMatches: true,
            includeScore: true,
            threshold: 0.0
        });
        const filteredUsers = fusejs.search(searchVal).map(d => d.item);
        const users = this.data.filter((d) => {
            return filteredUsers.map(_d => _d.id).includes(d.id)
        });
        return new UserList({data: users});
    }

    /**
     *
     * @param {Object} options
     * @param {any} [options.selectedLabel]
     * @param {any} [options.selectedLastChange]
     * @param {Boolean} [options.showDisabled]
     */
    filterUsersOnOptions({ selectedLabel, selectedLastChange, showDisabled = false }) {
        const filteredUsers = this.data.filter((d) => {
            if (!selectedLabel) {
                return true;
            }
            return d.data.labels.includes(selectedLabel);
        }).filter((d) => {
            return d.filterUserOnLastChange({selectedLastChange});
        }).filter((d) => {
            if (showDisabled) {
                return true;
            }
            return !d.data.isDisabled;
        })
        return new UserList({data: filteredUsers});
    }

    /**
     * Find user by id in list
     * @param {String} id
     */
    findById(id) {
        return this.data.find((d) => {
            return (d.id === id);
        })
    }

    /**
     *
     * @param {string} uuid
     */
    findByUUID(uuid) {
        return this.data.find(d => d.uuid === uuid);
    }

    /**
    * Array filter callback method
    * @callback ArrayFilterMethodCallback
    * @param {User} val
    * @param {Number} idx
    * @param {User[]} arr
    * @returns {Boolean}
    */

    /**
     * Array map callback method
     * @callback ArrayMapMethodCallback
     * @param {User} val
     * @param {Number} idx
     * @param {User[]} arr
     * @returns {any}
     */

    /**
     * Filter on user list
     * @param {ArrayFilterMethodCallback} fn
     * @returns {UserList}
     */
    filter(fn) {
        const filteredData = this.data.filter(fn);
        return new UserList({data: filteredData});
    }

    /**
     *
     * @param {ArrayMapMethodCallback} fn
     */
    forEach(fn) {
        this.data.forEach(fn);
    }

    /**
     * Map on user list
     * @param {ArrayMapMethodCallback} fn
     */
    map(fn) {
        return this.data.map(fn);
    }

    /**
     *
     * @param {ArrayFilterMethodCallback} fn
     * @returns {User}
     */
    find(fn) {
        return this.data.find(fn);
    }

    toJSON() {
        return this.data.map(d => d.toJSON());
    }
}

export default class User {
    constructor({ref, id, lastModifiedAt, data}) {
        this.ref = ref || '';
        this.id = id || '';
        this.lastModifiedAt = serializeFirebaseTimestamp(lastModifiedAt);
        /**
         * @type {UserData}
         */
        this.data = serializeUserData(this.setDefaults(data));
        this.originalData = this.setDefaults(data);
    }

    /**
     *
     * @param {{ age?: string, height?: string, why?:string, touchpoints?:string, food?:string, info?:string, bmr?:string, postpartum?:string, foodPref?: string }} notesObj
     */
    updateNotes({ age, height, why, touchpoints, food, foodPref, info, bmr, postpartum }) {
        const notesData = { age, height, why, touchpoints, food, foodPref, info, bmr, postpartum };
        Object.keys(notesData).forEach(key => notesData[key] === undefined && delete notesData[key]);

        this.data.notes = {
            ...this.data.notes,
            ...notesData
        }
        this.updateModifiedAt();
        return this;
    }

    /**
     *
     * @param {{ amount?: string, months?: string, startDate?:string, reference?:string }} planData
     */
    updatePlan({ amount, months, startDate, reference }) {
        const planData = { amount, months, startDate, reference };
        Object.keys(planData).forEach(key => planData[key] === undefined && delete planData[key]);

        this.data.plan = {
            ...this.data.plan,
            ...planData
        }
        this.updateModifiedAt();
        return this;
    }

    addProgressionRow() {
        const defaultDate = format(new Date(), 'dd/MM/yy');

        this.data.progression.push({
            createdAt: Firebase.firestore.Timestamp.now(),
            lastModifiedAt: Firebase.firestore.Timestamp.now(),
            uuid: getUUID(),
            date: defaultDate,
            weight: '',
            note: ''
        });
        this.updateModifiedAt();

        return this;
    }

    deleteProgressionRow(row) {
        this.data.progression = this.data.progression.filter(d => d.uuid !== row.uuid);
        this.updateModifiedAt();

        return this;
    }

    addPlanUpdateRow({ type }) {
        const defaultDate = format(new Date(), 'dd/MM/yy');

        this.data.planUpdates.push({
            createdAt: Firebase.firestore.Timestamp.now(),
            lastModifiedAt: Firebase.firestore.Timestamp.now(),
            uuid: getUUID(),
            date: defaultDate,
            type: type || '',
            note: ''
        });
        this.updateModifiedAt();

        return this;
    }

    deletePlanUpdateRow(row) {
        this.data.planUpdates = this.data.planUpdates.filter(d => d.uuid !== row.uuid);
        this.updateModifiedAt();

        return this;
    }

    updatePlanUpdateRow(row, data) {
        this.data.planUpdates = this.data.planUpdates.map((d) => {
            if (d.uuid === row.uuid) {
                return {
                    ...d,
                    ...data,
                    lastModifiedAt: Firebase.firestore.Timestamp.now(),
                }
            }
            return d;
        });
        this.updateModifiedAt();
        return this;
    }

    updateProgressionRow(row,data) {
        this.data.progression = this.data.progression.map((d) => {
            if (d.uuid === row.uuid) {
                return {
                    ...d,
                    ...data,
                    lastModifiedAt: Firebase.firestore.Timestamp.now(),
                }
            }
            return d;
        });
        this.updateModifiedAt();
        return this;
    }

    updateClientData(data) {
        Object.keys(data).forEach(key => data[key] === undefined && delete data[key]);

        this.data = {
            ...this.data,
            ...data
        }
        this.updateModifiedAt();
        return this;
    }

    addLabel(label) {
        this.data.labels.push(label);
        this.data.labels = Array.from(new Set(this.data.labels));
        this.updateModifiedAt();
        return this;
    }

    removeLabel(label) {
        this.data.labels = this.data.labels.filter(d => d !== label);
        this.updateModifiedAt();
        return this;
    }

    removeUpdateLabel(label) {
        this.data.labels = this.data.labels.filter(d => d !== label);
        let type = ['meal', 'workout', 'call'].includes(label) ? label : '';
        if (label === 'b4after') {
            type = 'pic';
        }
        this.data.planUpdates = [
            ...(this.data.planUpdates || []),
            {
                createdAt: Firebase.firestore.Timestamp.now(),
                lastModifiedAt: Firebase.firestore.Timestamp.now(),
                uuid: getUUID(),
                date: format(new Date(), 'dd/MM/yy'),
                type: type,
                note: `On removing ${label} label`
            }
        ]
        this.updateModifiedAt();
    }

    setImportant(isImportant) {
        this.data.important = !!isImportant;
        this.updateModifiedAt();
    }

    updateModifiedAt() {
        const modifiedTimestamp = Firebase.firestore.Timestamp.now();
        this.localModifiedAt = modifiedTimestamp;
        this.lastModifiedAt = modifiedTimestamp;
        this.data.lastModifiedAt = modifiedTimestamp;
        logger("Modified User timestamp", this, this.lastModifiedAt);
    }

    filterUserOnLastChange({ selectedLastChange }) {
        if (selectedLastChange?.type) {
            const updates = this.data.planUpdates || [];
            const typeUpdates = updates.filter((d) => {
                return d.type === selectedLastChange.type;
            }).sort((a, b) => {
                const AdateObj = a ? parse(a.date, 'dd/MM/yy', new Date()) : null;
                const BdateObj = b ? parse(b.date, 'dd/MM/yy', new Date()) : null;
                return compareAsc(BdateObj, AdateObj);
            })
            const lastTypeUpdate = typeUpdates[0];
            const lastUpdateDate = lastTypeUpdate ? getValidDate(lastTypeUpdate.date, 'dd/MM/yy') : null;
            const daysSinceLastChange = selectedLastChange?.days || 21;
            if (lastUpdateDate) {
                const differenceInDays = differenceInCalendarDays(new Date(), lastUpdateDate)
                return (differenceInDays >= daysSinceLastChange)
            }
            return false
        }
        return true;
    }

    static getDefault() {
        return {
            name: '',
            uuid: '',
            id: '',
            plan: {
                amount: '',
                months: '',
                startDate: '',
                reference: ''
            },
            notes: {
                why: '',
                height: '',
                age: '',
                touchpoints: '',
                food: '',
                info: '',
                bmr: '',
                postpartum: '',
            },
            progression: [],
            planUpdates: [],
            labels: [],
            mobile: '',
        };
    }

    /**
     *
     * @param {Object} options - Options
     * @param {Object} options.data - User data to be serialized
     * @returns {User[]} Serialized Users list
     */
    static serializeLocalData({data = []}) {
        /**
         * Serialized User list
         * @type {User[]}
         */
        const users = (data && data.map((d) => {
            const serializedUser = serializeUser(d);
            return new User(serializedUser);
        })) || [];
        return sortUsersByLatest(users);
    }

    sortProgressionByDate() {
        this.data.progression.sort((a, b) => {
            return getValidDate(b.date, 'dd/MM/yy').getTime() - getValidDate(a.date, 'dd/MM/yy').getTime();
        });
        return this;
    }

    getStartWeight() {
        const progression = this.data.progression || [];
        const sortedProgression = [...progression].sort((a, b) => {
            return getValidDate(b.date, 'dd/MM/yy').getTime() - getValidDate(a.date, 'dd/MM/yy').getTime();
        }).filter(d => !!d.weight);
        if (sortedProgression.length) {
            const firstWeight = sortedProgression[sortedProgression.length - 1].weight;
            const weight = Number(firstWeight.toLowerCase().replace('kg', ''));
            if (isNaN(weight)) {
                return null;
            }
            return weight;
        }
        return null;
    }

    /**
     * @returns {String} Name of user
     */
    get name() {
        return this.data.name;
    }

    /**
     * @returns {String} Name of user
     */
    get email() {
        return this.data.email || '';
    }

    /**
     * @returns {String} Name of user
     */
     get phone() {
        return this.data.phone || '';
    }

    /**
     * @returns {String} Name of user
     */
     get instagram() {
        return this.data.instagram || '';
    }


    /** Get User's UUID
     * @returns {String} UUID of user
     */
    get uuid() {
        return this.data.uuid;
    }

    get age() {
        if (this.data.notes.age && !isNaN(Number(this.data.notes.age))) {
            return Number(this.data.notes.age);
        }
        return null;
    }

    get height() {
        if (this.data.notes.height && !isNaN(Number(this.data.notes.height))) {
            return Number(this.data.notes.height);
        }
        return null;
    }
    /**
     * Calculate BMR if doesn't exist
     * 10 x weight (kg) + 6.25 x height (cm) – 5 x age (years) – 161
     */
    get bmr() {
        return this.data.notes.bmr || '';
        // if (this.data.notes.bmr) {
        //     return this.data.notes.bmr;
        // }
        // const startWeight = this.getStartWeight();
        // if (startWeight && this.age && this.height) {
        //     const bmr = Math.round((66.4730 + (13.7616 * startWeight) + (5.0033 * this.height) - (6.7550 * this.age)));
        //     const tdeee = Math.round(bmr * 1.375);
        //     return `${bmr}/${tdeee}`;
        // }
        // return '';
    }

    setDefaults(data) {
        const updatedData = Object.assign(User.getDefault(), data);
        return updatedData;
    }

    toJSON() {
        return {
            ref: this.ref,
            id: this.id,
            lastModifiedAt: this.lastModifiedAt,
            data: this.data
        };
    }
}

export class CollectionList {
    /**
     * @param {Object} options
     * @param {Array<any>} options.data
     */
    constructor({data} = {data: []}) {
        this.name = 'CollectionList';
        this.data = data || [];
        this.updateLastModifiedAt();
    }

    get length() {
        return this.data.length;
    }

    get items() {
        if (this.data) {
            return this.data.map(d => d.data);
        }
        return [];
    }

    /**
     *
     * @param {Object} updatedItem
     */
    updateItem(updatedItem) {
        this.data = this.data.map((d) => {
            if (d.uuid === updatedItem.uuid) {
                return updatedItem;
            }
            return d;
        });
        this.updateLastModifiedAt();
        return this;
    }

    /**
     *
     * @param {Object} item
     */
    addItem(item) {
        this.data.push(item);
        this.updateLastModifiedAt();
        return this;
    }

    /**
     *
     * @param {Object} item
     */
    removeItem(item) {
        this.data = this.data.filter((d) => {
            return d.uuid !== item?.uuid;
        });
        this.updateLastModifiedAt();
        return this;
    }

    /**
     *
     * @param {Array<Object>} items
     */
    updateList(items) {
        logger(`Updating ${this.name} list`, items);
        items.forEach((item) => {
            if (this.findByUUID(item.uuid)) {
                this.updateItem(item);
            } else {
                this.addItem(item);
            }
        });
        return this;
    }

    updateLastModifiedAt() {
        const timestamp = this.getLastModifiedItemTime();
        if (timestamp instanceof Firebase.firestore.Timestamp) {
            this.lastModifiedAt = timestamp.toDate().getTime();
        } else {
            this.lastModifiedAt = null;
        }
    }

    updateFirebaseTimestamp() {
        this.lastFirebaseUpdateTimestamp = Firebase.firestore.Timestamp.now();
    }

    /**
     *
     * @param {Site[]} items
     */
    removeItems(items) {
        const uuids = items.map(d => d.uuid);
        this.data = this.data.filter((d) => {
            return !uuids.includes(d.uuid);
        });
        this.updateLastModifiedAt();
        return this;
    }

    sortyBy() {
        const sortedItems = this.data.sort((a, b) => {
            return a?.sortId?.localeCompare(b.sortId, [], {numeric: true, sensitivity: 'base'});
        });
        return this.createSelfInstance(this.constructor, {data: sortedItems});
    }

    getLastModifiedItemTime() {
        const Timestamp = Firebase.firestore.Timestamp;
        const lastModifiedUser = this.data.filter((d) => {
            return (d.lastModifiedAt instanceof Timestamp);
        }).sort((doc1, doc2) => {
            return doc2.lastModifiedAt.toMillis() - doc1.lastModifiedAt.toMillis();
        })[0];
        return lastModifiedUser?.lastModifiedAt;
    }

    /**
     *
     * @param {Object} options
     * @param {String} options.searchVal
     * @returns {Object}
     */
    filterOnSearch({searchVal}) {
        if (!this.items.length) {
            return this.createSelfInstance(this.constructor);
        }
        if (!searchVal) {
            return this;
        }
        const fusejs = new Fuse(this.items, {
            keys: ['name'],
            includeMatches: true,
            includeScore: true,
            threshold: 0.0
        });
        const filteredItems = fusejs.search(searchVal).map(d => d.item);
        const items = this.data.filter((d) => {
            return filteredItems.map(_d => _d.uuid).includes(d.uuid);
        });

        return this.createSelfInstance(this.constructor, {data: items});
    }

    /**
     * @param {Object} constructor
     * @param {Object} data
     * @returns {CollectionList}
     */
    createSelfInstance(constructor, data) {
        return new constructor(data);
    }

    /**
     * Find by id in list
     * @param {String} id
     */
    findById(id) {
        return this.data.find((d) => {
            return (d.id === id);
        });
    }

    /**
     * Find by uuid in list
     * @param {string} uuid
     */
    findByUUID(uuid) {
        return this.data.find(d => d.uuid === uuid);
    }

    /**
     * Filter on user list
     * @param {ArrayFilterMethodCallback} fn
     * @returns {CollectionList}
     */
    filter(fn) {
        const filteredData = this.data.filter(fn);
        return this.createSelfInstance(this.constructor, {data: filteredData});
    }

    /**
     *
     * @param {ArrayMapMethodCallback} fn
     */
    forEach(fn) {
        this.data.forEach(fn);
    }

    /**
     * Map on user list
     * @param {ArrayMapMethodCallback} fn
     */
    map(fn) {
        return this.data.map(fn);
    }

    /**
     *
     * @param {ArrayFilterMethodCallback} fn
     * @returns {Site}
     */
    find(fn) {
        return this.data.find(fn);
    }

    toJSON() {
        return this.data.map(d => d.toJSON());
    }
}

export class ReminderList extends CollectionList {
    /**
     * @param {Object} options
     * @param {Site[]} options.data
     */
    constructor({data} = {data: []}) {
        super({data});
        this.name = 'ReminderList';
    }

    get reminders() {
        const benchmarkDate = subDays(new Date(), 30);
        return this.data.filter((rData) => {
            const d = rData.data;
            const reminderDate = parseDate(d.reminderDate, 'dd/MM/yy', new Date());
            return isAfter(reminderDate, benchmarkDate);
        }).filter((rData) => {
            return !rData.data.deleted;
        }).sort((a,b) => {
            return a.data.user_uuid.localeCompare(b.data.user_uuid);
        }).sort((a,b) => {
            const dateA = parseDate(a.data.reminderDate, 'dd/MM/yy', new Date());
            const dateB = parseDate(b.data.reminderDate, 'dd/MM/yy', new Date());
            return isBefore(dateA, dateB) ? 1 : -1;
        });
    }

    get upcomingReminders() {
        const benchmarkDate = new Date();
        let upcoming = this.data.filter((rData) => {
            const d = rData.data;
            const reminderDate = parseDate(d.reminderDate, 'dd/MM/yy', new Date());
            const showTillDate = addDays(new Date(), 3);
            return isAfter(reminderDate, benchmarkDate) && isBefore(reminderDate, showTillDate);
        }).filter((rData) => {
            return !rData.data.finished && !rData.data.deleted;
        }).sort((a,b) => {
            return a.data.user_uuid.localeCompare(b.data.user_uuid);
        }).sort((a,b) => {
            const dateA = parseDate(a.data.reminderDate, 'dd/MM/yy', new Date());
            const dateB = parseDate(b.data.reminderDate, 'dd/MM/yy', new Date());
            return isBefore(dateA, dateB) ? -1 : 1;
        });
        if (!upcoming.length) {
            upcoming = this.data.filter((rData) => {
                const d = rData.data;
                const reminderDate = parseDate(d.reminderDate, 'dd/MM/yy', new Date());
                return isAfter(reminderDate, benchmarkDate);
            }).filter((rData) => {
                return !rData.data.finished && !rData.data.deleted;
            }).sort((a,b) => {
                return a.data.user_uuid.localeCompare(b.data.user_uuid);
            }).sort((a,b) => {
                const dateA = parseDate(a.data.reminderDate, 'dd/MM/yy', new Date());
                const dateB = parseDate(b.data.reminderDate, 'dd/MM/yy', new Date());
                return isBefore(dateA, dateB) ? -1 : 1;
            });
        }
        return upcoming.slice(0, 10);
    }
}

export class CollectionItem {
    constructor({ref, id, lastModifiedAt, data}) {
        this.ref = ref || '';
        this.id = id || '';
        this.lastModifiedAt = serializeFirebaseTimestamp(lastModifiedAt);
        /**
         * @type {SiteData}
         */
        this.data = transformDataTimestamps(this.setDefaults(data));
        this.originalData = this.setDefaults(data);
    }

    updateModifiedAt() {
        const modifiedTimestamp = Firebase.firestore.Timestamp.now();
        this.localModifiedAt = modifiedTimestamp;
        this.lastModifiedAt = modifiedTimestamp;
        this.data.lastModifiedAt = modifiedTimestamp;
        logger('Modified item timestamp', this, this.lastModifiedAt);
    }

    get List() {
        return CollectionList;
    }

    getDefault() {
        return {};
    }

    /**
     *
     * @param {Object} options - Options
     * @param {Object} options.data - User data to be serialized
     * @returns {Object[]} Serialized Users list
     */
    static serializeLocalData({data = []}) {
        /**
         * Serialized item list
         * @type {Object[]}
         */
        const items = (data && data.map((d) => {
            const serializedItem = transformTimestamps(d);
            return new this(serializedItem);
        })) || [];
        return sortByLatest(items);
    }

    /** Get User's UUID
     * @returns {String} UUID of user
     */
    get uuid() {
        return this.data.uuid;
    }

    setDefaults(data) {
        const updatedData = Object.assign(this.getDefault(), data);
        return updatedData;
    }

    toJSON() {
        return {
            ref: this.ref,
            id: this.id,
            lastModifiedAt: this.lastModifiedAt,
            data: this.data
        };
    }

    /**
     * Create new instance of same class
     * @param {Object} constructor
     * @param {Object} data
     * @returns {CollectionList}
     */
    createSelfInstance(constructor, data) {
        return new constructor(data);
    }
}

export class Reminder extends CollectionItem{
    constructor({ref, id, lastModifiedAt, data}) {
        super({ref, id, lastModifiedAt, data});
    }

    static getDefault() {
        return {
            uuid: "",
            user_uuid: "",
            type: "",
            startDate: "",
            reminderDate: "",
            deleted: false,
            finished: false,
            reason: ''
        };
    }

    static get List() {
        return ReminderList;
    }

    get sortId() {
        return this.uuid;
    }

    /** Get Site's siteId
     * @returns {String} siteId of site
     */
    get reminderId() {
        return this.data.uuid;
    }

    setFinished(value) {
        this.data.finished = !!value;
        this.updateModifiedAt();
    }
}
