import React from 'react';
import {ReactComponent as LeftArrowIcon} from '../../images/icons/arrow-left.svg';
import './BackButton.css';

const BackButton = ({hidden = false, onClick = () => {}}) => {
    if (hidden) {
        return;
    }

    return (
        <button className='gh-portal-btn-back' onClick={e => onClick(e)}>
            <LeftArrowIcon />
        </button>
    );
}

export default BackButton;
