import { firebaseFns } from './firebase';
import OfflineStore from './localstorage';
import parseDate from 'date-fns/parse';
import formatDate from 'date-fns/format';
import isValidDate from 'date-fns/isValid';
import { logger } from './logger';
const NhData = require('../data/nh-data.json');
var Faker = require('faker');
Faker.locale = 'en_IND';

async function updateOfflineStore(key, value) {
    await OfflineStore.setItem(key, value);
}

export const getValidDate = (dateStr, format = 'dd/MM') => {
    if (dateStr) {
        const parsedDate = parseDate(dateStr, format, new Date());
        return isValidDate(parsedDate) ? parsedDate : null;
    }
    return null;
}

function createProgressionData(d) {
    const startWeight = (d['Start Weight'] || '').trim();
    const startWtDate = getValidDate((d['Start Wt Date'] || '').trim());
    const currentWeight = (d['Current Weight'] || '').trim();
    const currentWtDt = getValidDate((d['Current Wt Date'] || '').trim());
    const progression = [];
    if (startWeight && startWtDate) {
        progression.push({
            date: formatDate(startWtDate, 'dd/MM'),
            weight: startWeight
        })
    }
    if (currentWeight && currentWtDt) {
        progression.push({
            date: formatDate(currentWtDt, 'dd/MM'),
            weight: currentWeight
        })
    }
    return progression.sort((a, b) => {
        return getValidDate(b.date).getTime() - getValidDate(a.date).getTime();
    });
}

export function parseUserCSVData({data, meta, errors}) {
    const users = data.map((d) => {
        const [height = '', age = ''] = d['Height & Age']?.split(',').map(d => d.trim()) || ['',''];
        return {
            uuid: Faker.random.uuid(),
            id: d['Id']?.trim() || '',
            mobile: d['Mobile'],
            name: d['Name'],
            type: d['Type'],
            plan: {
                startDate: d['Start Date'],
                amount: d['Plan (amount)'],
                months: d['Plan (months)'],
                reference: d['Reference']
            },
            progression: createProgressionData(d),
            notes: {
                postpartum: d['Postpartum'],
                info: d['Info'],
                why: d['Why'],
                touchpoints: d['Touchpoints'],
                bmr: d['BMR & TDEE'],
                height: height,
                age: age
            },
            labels: []
        }
    })
    // uploadUsersCSVtoFirebase({data: users});
    return users;
}

async function updatedLocalLastUpdated({collection, data, timestamp}) {
    try {
        const {lastUpdated = {}, nhData = {}} = await getLocalData() || {};
        await updateOfflineStore('nhData', {
            ...nhData,
            [collection]: data
        })
        if (timestamp)  {
            await updateOfflineStore('lastUpdatedAt', {
                ...lastUpdated,
                [collection]: timestamp
            });
        }
    } catch (e) {
        console.log("Error in updating local", e);
    }
}

function mergeLocalAndFirebaseChanges({localChanges, firebaseDocChanges}) {
    const changedDocIds = firebaseDocChanges.map(d => d.doc.id);
    const updatedData = localChanges.filter((d) => {
        return !changedDocIds.includes(d.id);
    });
    firebaseDocChanges.filter(d => d.type !== 'removed').forEach((docChange) => {
        updatedData.splice(docChange.newIndex, 0, {
            id: docChange.doc.id,
            ref: docChange.doc.ref.path,
            lastModifiedAt: docChange.doc.get('lastModifiedAt'),
            data: docChange.doc.data()
        });
    })
    return updatedData.sort((doc1, doc2) => {
        if (doc1?.lastModifiedAt?.seconds && doc2?.lastModifiedAt?.seconds) {
            return getTimestampFromObject({ timestamp: doc2.lastModifiedAt })?.toMillis() - getTimestampFromObject({ timestamp: doc1.lastModifiedAt })?.toMillis();
        }
        return 0;
    });
}

export const getTimestampFromObject = ({ timestamp } = {}) => {
    const { Firestore } = firebaseFns();
    if (timestamp?.seconds && timestamp?.nanoseconds) {
        return new Firestore.Timestamp(timestamp.seconds, timestamp.nanoseconds);
    }
    return null;
}

export async function fetchCollectionData({collection, listener, forceAll = false}) {
    const  {Firestore, FirestoreDb} = firebaseFns();
    let {lastUpdatedAt, data} = await getLocalCollectionData({collection});

    // Send Local Data
    let firebaseQueryRef;
    let listenerFiredOnce = false;
    if (!lastUpdatedAt || !data || forceAll) {
        firebaseQueryRef = FirestoreDb.collection(collection);
        data = [];
    } else {
        const lastTimestamp = Firestore.Timestamp.fromDate((new Date(lastUpdatedAt)));
        firebaseQueryRef = FirestoreDb.collection(collection).where('lastModifiedAt', '>', lastTimestamp)
        data = data || [];
        const finalData = mergeLocalAndFirebaseChanges({localChanges: data,firebaseDocChanges: []});
        listenerFiredOnce = true;
        listener({collection, data: finalData, timestamp: lastUpdatedAt});
    }
    return firebaseQueryRef.onSnapshot(async (qSnapshot) => {
        let {lastUpdatedAt, data} = await getLocalCollectionData({collection});
        data = data || [];
        const docChanges = qSnapshot.docChanges();
        console.group(`Collection ${collection} data since ${lastUpdatedAt || 'start'}`);
        logger("Found updated documents", docChanges);
        console.groupEnd();
        if (!listenerFiredOnce || docChanges?.length > 0) {
            const finalData = mergeLocalAndFirebaseChanges({ localChanges: data, firebaseDocChanges: docChanges });
            // const lastModifiedAt = (finalData[0] && finalData[0].lastModifiedAt) || null;
            const lastModifiedAt = finalData?.[0]?.lastModifiedAt || null;
            const isoTimestamp = getDateFromTimestamp({ timestamp: getTimestampFromObject({timestamp: lastModifiedAt}) });
            await updatedLocalLastUpdated({ collection, data: finalData, timestamp: isoTimestamp});
            listener({ collection, data: finalData, timestamp: isoTimestamp });
        }
    });
}

function getDateFromTimestamp({timestamp}) {
    const {Firestore} = firebaseFns();
    if (timestamp?.toDate) {
        return timestamp.toDate().toISOString();
    } else if (timestamp?.seconds) {
        return (new Firestore.Timestamp(timestamp.seconds, timestamp.nanoseconds)).toDate().toISOString();
    }
    return null;
}

async function getLocalCollectionData({collection}) {
    const {lastUpdatedAt, nhData} = await getLocalData() || {};
    return {
        lastUpdatedAt: (lastUpdatedAt && lastUpdatedAt[collection]) || null,
        data: (nhData && nhData[collection]) || null
    }
}

export function transformFromFirebaseTimestamps(user) {
    const newObj = {};
    Object.keys(user).forEach((d) => {
        if (['progression'].includes(d)) {
            newObj[d] = user[d].map((_d) => {
                return {
                    ..._d,
                    createdAt: _d.createdAt?.toJSON?.() || _d.createdAt,
                    lastModifiedAt: _d.lastModifiedAt.toJSON?.() || _d.lastModifiedAt,
                }
            })
        } else if (['createdAt', 'lastModifiedAt'].includes(d)) {
            newObj[d] = user[d]?.toJSON?.() || user[d];
        } else {
            newObj[d] = user[d];
        }
    })
    return newObj;
}

export async function getLocalData() {
    try {
        const lastUpdatedAt = await OfflineStore.getItem('lastUpdatedAt');
        const nhData = await OfflineStore.getItem('nhData');
        return {
            lastUpdatedAt,
            nhData
        };
    } catch (e) {
        return {};
    }
}

export async function listenDataUpdates(listener) {
    listener(NhData);
}