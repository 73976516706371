import OfflineStore from "../utils/localstorage";

export async function updateLocalCollectionData({ collection, data, timestamp }) {
    try {
        const { lastUpdatedAt = {}, nhData = {} } = await getLocalData() || {};
        await OfflineStore.setItem('nhData', {
            ...nhData,
            [collection]: data
        })
        if (timestamp) {
            await OfflineStore.setItem('lastUpdatedAt', {
                ...lastUpdatedAt,
                [collection]: timestamp
            });
        }
    } catch (e) {
        console.log("Error in updating local", e);
    }
}

/**
 * Fetch collection's locally stored data
 * @param {Object} options -
 * @param {string} options.collection - Name of collection
 * @return {Promise<{lastUpdatedAt: string, data: any}>} Local collection data with last updated date
 */
export async function getLocalCollectionData({ collection }) {
    const { lastUpdatedAt, nhData } = await getLocalData();
    return {
        lastUpdatedAt: lastUpdatedAt?.[collection] || null,
        data: nhData?.[collection] || null
    }
}

/**
 * Get local app data
 * @return {Promise<{lastUpdatedAt: any, nhData: any}>} Local app data
 */
export async function getLocalData() {
    try {
        const lastUpdatedAt = await OfflineStore.getItem('lastUpdatedAt');
        const nhData = await OfflineStore.getItem('nhData');
        return {
            lastUpdatedAt,
            nhData
        };
    } catch (e) {
        return {
            lastUpdatedAt: null,
            nhData: null
        };
    }
}