import React from 'react';
import './ProfileComponent.css';

const ProfileComponent = () => {
    return (
        <div className="TwoColumnWithFeaturesAndTestimonial__ImageContainer-sc-1lrv25x-13 fibciF XXsnipcss_extracted_selector_selectionXX nh-profile-component">
            <img src="https://nurturinghealth.in/static/media/coach-profile.afd8f18d.jpeg" className="TwoColumnWithFeaturesAndTestimonial__Image-sc-1lrv25x-14 bjZZZt" alt="Pragati Jindal"/>
            <div className="TwoColumnWithFeaturesAndTestimonial__Testimonial-sc-1lrv25x-17 bOSqiS">
                <svg viewBox="0 0 24 24" className="TwoColumnWithFeaturesAndTestimonial__QuotesLeftIcon-sc-1lrv25x-18 ifZihH">
                    <path fill="currentColor" d="M4.583 17.321C3.553 16.227 3 15 3 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 01-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179zm10 0C13.553 16.227 13 15 13 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 01-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179z">
                    </path>
                </svg>
                <blockquote className="TwoColumnWithFeaturesAndTestimonial__Quote-sc-1lrv25x-19 bSzqeF" style={{fontSize: '13px'}}>
                    Good health means listening to what your body is telling you, and results will automatically follow.
                </blockquote>
                <p className="TwoColumnWithFeaturesAndTestimonial__CustomerName-sc-1lrv25x-20 daccCm">
                    Pragati Jindal
                </p>
                <p className="TwoColumnWithFeaturesAndTestimonial__CustomerCompany-sc-1lrv25x-21 hfkrUv">
                    Nesta Certified Health Coach
                </p>
            </div>
        </div>
    )
}

export default ProfileComponent;