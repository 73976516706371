import Firebase from "../utils/firebase";
import { logger } from "../utils/logger";


/**
 * Listen to Firebase collection changes
 * @param {Object} options - Options
 * @param {String} options.collection - Name of collection
 * @param {Object} options.listener - Callback listener
 * @param {String} options.lastModifiedAt - Last modified date when local data is available for collection
 */
export function listenCollectionData({collection, listener, lastModifiedAt}) {
    let firebaseQueryRef;
    if (!lastModifiedAt) {
        firebaseQueryRef = Firebase.db.collection(collection);
    } else {
        const lastModifiedTimestamp = Firebase.firestore.Timestamp.fromDate((new Date(lastModifiedAt)));
        firebaseQueryRef = Firebase.db.collection(collection).where('lastModifiedAt', '>', lastModifiedTimestamp)
    }
    return firebaseQueryRef.onSnapshot((snapshot) => {
        const docChanges = snapshot.docChanges();
        logger("On Firestore Snapshot", { collection, docChanges, docs: snapshot.docs, snapshot })
        listener({ collection, docChanges, docs: snapshot.docs, snapshot});
    });
}