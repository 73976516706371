import Firebase from '../utils/firebase';

export function isNullOrUndefined(val) {
    return (val === undefined && val === null);
}

export function isFirebaseTimestampObject(timestamp) {
    return (
        typeof timestamp === 'object' &&
        (!isNullOrUndefined(timestamp?.seconds) || isNullOrUndefined(timestamp?._seconds)) &&
        (!isNullOrUndefined(timestamp?.nanoseconds) || !isNullOrUndefined(timestamp?._nanoseconds))
    );
}

export function serializeFirebaseTimestamp(timestamp) {
    try {
        if (timestamp instanceof Firebase.firestore.Timestamp) {
            return timestamp;
        } else if (isFirebaseTimestampObject(timestamp)) {
            const seconds = !isNullOrUndefined(timestamp.seconds) ? timestamp.seconds : timestamp._seconds;
            const nanoseconds = !isNullOrUndefined(timestamp.nanoseconds) ? timestamp.nanoseconds : timestamp._nanoseconds;
            return new Firebase.firestore.Timestamp(seconds, nanoseconds);
        } else if (!timestamp) {
            return null;
        }
        return null;
    } catch (e) {
        return null;
    }
}

export function transformTimestamps(user) {
    const data = user.data;
    user.lastModifiedAt = serializeFirebaseTimestamp(user.lastModifiedAt);
    transformDataTimestamps(data);
    return user;
}

/**
 *
 * @param {any} data
 * @returns {Object} Serialized collection object
 */
export function transformDataTimestamps(data) {
    data.createdAt = serializeFirebaseTimestamp(data.createdAt);
    data.lastModifiedAt = serializeFirebaseTimestamp(data.lastModifiedAt);
    return data;
}

/**
 * Sort users by latest modified user first
 * @param {Array<Object>} data - data list
 * @returns {Array<Object>} Serialized data list
 */
export function sortByLatest(data = []) {
    const Timestamp = Firebase.firestore.Timestamp;
    return [...data].sort((doc1, doc2) => {
        if (doc1?.lastModifiedAt instanceof Timestamp && doc2?.lastModifiedAt instanceof Timestamp) {
            return doc2.lastModifiedAt.toMillis() - doc1.lastModifiedAt.toMillis();
        }
        return 0;
    });
}

