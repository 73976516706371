import React, {  useMemo, useState } from 'react';
import { Tabs } from 'antd-mobile';
import { Button, Checkbox, Divider, Drawer, Input, InputNumber, Select, Tag } from 'antd';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { CheckOutlined, CloseOutlined, LogoutOutlined, MenuOutlined,UserAddOutlined, WhatsAppOutlined } from '@ant-design/icons';
import Avatar from 'antd/lib/avatar/avatar';
import NewClient from './NewClient';
import { getValidDate } from '../../utils/data';
import dateDifferenceInWeeks from 'date-fns/differenceInWeeks'
import { AvailableClientLabels } from '../../utils/helpers';
import formatDate from 'date-fns/format'
import Firebase from '../../utils/firebase';
import NewClientHome from './NewClientHome';
import { useLayout } from '../../utils/hooks/use-media';
import { useFirebaseData } from '../../utils/hooks/use-firebase';
import './AdminHome.css';
import Reminders from './Reminders';

/**
 * @typedef {import('../../services/users').UserList} UserList
 */

/**
 * @typedef {import('../../services/users').default} User
 */

const { Search } = Input;

const TABS = [
    {
        title: 'Clients'
    },
    {
        title: 'Add'
    },
    {
        title: 'Reminders'
    },
];


const FlexDiv = ({ children, direction = 'row', alignItems = "normal" }) => {
    return (
        <div style={{ display: 'flex', flexDirection: direction, alignItems }}>
            {children}
        </div>
    )
}

function getClientStartDate({ client }) {
    let startDateStr = client?.plan?.startDate || '';
    if (typeof startDateStr !== 'string') {
        return '';
    }
    let format = 'dd/MM/yy';
    if (startDateStr.split('/')?.[2]?.trim().length === 4) {
        startDateStr = startDateStr.replace('0200', '2020');
        format = 'dd/MM/yyyy';
    } else if (startDateStr.split('-')?.[1]?.trim().length > 0) {
        format = 'dd-MM/yy';
    } else if (startDateStr.split('/')?.length === 2) {
        format = 'dd/MM';
    }
    return getValidDate(startDateStr, format);
}

function getDayWeekText({ client, startDate }) {
    startDate = startDate || getClientStartDate({ client });
    const isOneMonth = client?.plan?.months === 1 || client?.plan?.months === '1';
    const isTwoMonth = client?.plan?.months === 2 || client?.plan?.months === '2';
    if (startDate) {
        const week = dateDifferenceInWeeks(new Date(), startDate);
        // const days = dateDfferenceInCalendarDays(new Date(), startDate);
        const startDateFormat = formatDate(startDate, 'dd MMM');
        const str = `${startDateFormat} | Week ${week + 1}`;
        if (isOneMonth) {
            return str + ' || 1M';
        } else if (isTwoMonth) {
            return str + ' || 2M';
        }
        return str;
    }
}

const ChatItemActions = ({ selectedLabel, mobile, user}) => {
    return useMemo(() => {
        return (
            <FlexDiv alignItems='center'>
                <RemoveUpdateLabelIcon selectedLabel={selectedLabel} user={user} />
                <a style={{ padding: '0 12px', cursor: 'pointer', color: '#54b461', marginTop: '4px' }} href={`https://wa.me/${mobile}?text=Hey`} onClick={(e) => {
                    e.stopPropagation();
                    return false;
                }}>
                    <WhatsAppOutlined style={{ fontSize: '20px' }} />
                </a>
                <RemoveLabelIcon selectedLabel={selectedLabel} user={user} />
            </FlexDiv>
        );
    }, [mobile, selectedLabel, user])
}

const ChatItemAvatar = ({ name, important, isDisabled, user}) => {
    const { dispatch } = useFirebaseData();

    return useMemo(() => {
        const firstLetter = name?.[0] || 'U';
        let avatarBackground = !!important ? '#f5009f' : '#f56a00';
        if (isDisabled) {
            avatarBackground = 'grey';
        }
        return (
            <div
                style={{ marginRight: '12px' }}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    user.setImportant(!important);
                    dispatch({ type: "updateUser", payload: user });
                }}
            >
                <Avatar
                    style={{
                        backgroundColor: avatarBackground,
                        verticalAlign: 'middle',
                    }}
                    size="large"
                >
                    {firstLetter}
                </Avatar>
            </div>
        );
    }, [user, important, name, isDisabled, dispatch]);
}

/**
 *
 * @param {Object} props
 * @param {string} props.selectedLabel
 * @param {User} props.user
 */
const ChatListItem = ({ selectedLabel, user }) => {
    let history = useHistory();
    const { uuid: routeUuid } = useParams();
    const { name, important, isDisabled, uuid, mobile} = user.data;
    const startDate = getClientStartDate({ client: user.data })
    const isSelected = uuid === routeUuid;
    return useMemo(() => {
        const client = user.data;
        const parentStyle = {
            cursor: 'pointer'
        };
        if (isSelected) {
            parentStyle.background = '#f5f5f5'
        }
        return (
            <section className="nh-clientlist-item" style={parentStyle} onClick={() => {
                history.push(`/client/${uuid}`);
            }}>
                <div className='gh-portal-list-detail'>
                    <FlexDiv>
                        <ChatItemAvatar user={user} name={name} important={important} isDisabled={isDisabled} />
                        <div style={{ display: 'flex', flexGrow: 1 }}>
                            <div style={{ flexGrow: 1 }}>
                                <h3 style={{
                                    width: '170px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}>{name}</h3>
                                <div style={{ flexGrow: 1, color: 'black' }}>
                                    <p> {getDayWeekText({ client, startDate })} </p>
                                </div>
                            </div>
                            <ChatItemActions selectedLabel={selectedLabel} user={user} mobile={mobile || '919821636829'} />
                        </div>
                    </FlexDiv>
                </div>
            </section>
        );
    }, [user, name, important, isDisabled, isSelected, selectedLabel, history, mobile, startDate, uuid])
};

/**
 *
 * @param {Object} props
 * @param {String} props.selectedLabel
 * @param {User} props.user
 */
const RemoveLabelIcon = ({ selectedLabel, user }) => {
    const { dispatch: dispatchFn } = useFirebaseData();
    if (selectedLabel) {
        return (
            <CloseOutlined style={{ fontSize: '18px', color: 'red' }} onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                user.removeLabel(selectedLabel);
                dispatchFn({type: 'updateUser', payload: user});
            }} />
        );
    }
    return null;
}

/**
 *
 * @param {Object} props
 * @param {String} props.selectedLabel
 * @param {User} props.user
 */
const RemoveUpdateLabelIcon = ({ selectedLabel, user }) => {
    const {dispatch: dispatchFn} = useFirebaseData();
    if (selectedLabel) {
        return (
            <CheckOutlined style={{ fontSize: '18px', color: 'green' }} onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                user.removeUpdateLabel(selectedLabel);
                dispatchFn({ type: 'updateUser', payload: user});
            }} />
        );
    }
    return null;
}

/**
 *
 * @param {Object} props
 * @param {UserList} props.users
 * @param {String} props.selectedLabel
 * @param {String} props.selectedSorting
 * @param {any} props.selectedLastChange
 */
const ChatListItems = ({ users, selectedLabel, selectedSorting }) => {
    const sortedUsers = users.sortyBy(selectedSorting);
    const chatItems = sortedUsers.map((d) => {
        return (<ChatListItem user={d} key={d.data.uuid} selectedLabel={selectedLabel} />);
    });
    if (chatItems.length === 0) {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '24px 0' }}>
                <p style={{margin: "auto"}}> No clients found </p>
            </div>
        )
    }
    return (
        <>
            {chatItems}
        </>
    );
}

/**
 *
 * @param {Object} options
 * @param {UserList} options.users
 * @param {String} options.selectedLabel
 * @param {any} options.selectedSorting
 * @param {any} options.selectedLastChange
 */
const ChatList = ({ users, selectedLabel, selectedSorting, selectedLastChange }) => {
    return (
        <div className='gh-portal-list client-list outline mb6'>
            <ChatListItems users={users} selectedLabel={selectedLabel} selectedSorting={selectedSorting} selectedLastChange={selectedLastChange} />
        </div>
    );
}

const SearchBox = ({ onSearch, onDrawerOpen }) => {
    const prefixIcon = (
        <MenuOutlined
            style={{ marginRight: '4px' }}
            onClick={() => {
                onDrawerOpen();
            }}
        />
    );
    return (
        <div style={{ display: 'flex', flexGrow: 1, alignItems: 'center', width: '100%', }}>
            <Search
                allowClear
                size="large"
                placeholder="Search..."
                onSearch={() => {}}
                prefix={prefixIcon}
                onChange={(e) => onSearch({
                    val: e?.target?.value || ''
                })}
                style={{ borderRadius: '6px', flexGrow: 1, width: '80%' }}
            />
        </div>
    );
}

const HomeTab = () => {
    const {data} = useFirebaseData();
    const layout = useLayout();
    const [searchVal, setSearchVal] = useState('');
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [selectedLabel, setSelectedLabel] = useState();
    const [selectedSorting, setSelectedSorting] = useState('startDate');
    const [selectedLastChange, setSelectedLastChange] = useState({ days: 21 });
    const [showDisabled, setShowDisabled] = useState(false);

    const filteredUsers = data.filterOnSearch({searchVal});
    const finalUsers = filteredUsers.filterUsersOnOptions({ selectedLabel, selectedLastChange, showDisabled });
    return (
        <>
            <div className={`gh-portal-content-header ${layout}`}>
                <header className='gh-portal-signup-header'>
                    <SearchBox
                        onSearch={({ val }) => {
                            setSearchVal(val);
                        }}

                        onDrawerOpen={() => {
                            setDrawerOpen(true);
                        }}
                    />
                    <HomeFilters
                        {...{ selectedLabel, setSelectedLabel, selectedSorting, setSelectedSorting, selectedLastChange, setSelectedLastChange }}
                    />
                </header>
            </div>
            <div className={`gh-portal-content signup adminhome ${layout}`} style={{ paddingTop: 0, flexGrow: 1 }}>
                <ChatListSettings users={data} selectedLabel={selectedLabel} />
                <ChatList users={finalUsers} selectedLabel={selectedLabel} selectedSorting={selectedSorting} selectedLastChange={selectedLastChange} />
            </div>
            <NavDrawer
                setDrawerOpen={setDrawerOpen}
                isDrawerOpen={isDrawerOpen}
                {...{
                    selectedLabel, setSelectedLabel, selectedSorting, setSelectedSorting, selectedLastChange, setSelectedLastChange,
                    showDisabled, setShowDisabled
                }}
            />
        </>
    )
}

/**
 *
 * @param {Object} props
 * @param {UserList} props.users
 * @param {any} props.selectedLabel
 */
const ChatListSettings = ({users, selectedLabel}) => {
    const {dispatch: dispatchFn} = useFirebaseData();
    if (!selectedLabel) {
        return null;
    }
    const filteredUserIds = users.filterUsersOnOptions({ selectedLabel }).map(d => d.id);
    const remainingUsers = users.filter((d) => {
        return !filteredUserIds.includes(d.id);
    })
    const options = remainingUsers.map((d) => {
        return {
            label: d.data.name,
            value: d.data.id
        }
    });
    options.push({
        label: 'Add label to...',
        value: ''
    });
    return (
        <div style={{marginBottom: '12px'}}>
            <Select
                options={options}
                defaultValue={''}
                style={{ width: '100%' }}
                placeholder="Add Users to Label..."
                showSearch={true}
                value={''}
                onChange={(value) => {
                    const selectedClient = remainingUsers.findById(value);
                    if (selectedClient) {
                        selectedClient.addLabel(selectedLabel);
                        dispatchFn({ type: 'updateUser', payload: selectedClient });
                    }
                }}
                filterOption={(input, option) => {
                    const usr = users.findById(option.value)
                    if (usr) {
                        return usr.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    return false;
                }}
            />
        </div>
    );
}

const HomeFilters = ({ selectedLabel, setSelectedLabel, selectedSorting, setSelectedSorting, selectedLastChange, setSelectedLastChange }) => {
    const LabelTag = () => {
        const selectedLabelObj = selectedLabel ? AvailableClientLabels.find(d => d.value === selectedLabel) : null;
        if (selectedLabelObj) {
            return (
                <Tag color={selectedLabelObj.color} closable={true} onClose={() => {
                    setSelectedLabel();
                }} style={{ marginTop: '12px', fontWeight: 'bold' }}>
                    {selectedLabelObj.label}
                </Tag>
            )
        }
        return null;
    }

    const SortingTag = () => {
        if (selectedSorting === 'alphabetical') {
            return (
                <Tag closable={true} onClose={() => {
                    setSelectedSorting('startDate');
                }} style={{ marginTop: '12px', fontWeight: 'bold' }}>
                    Alphabetical
                </Tag>
            )
        }
        return null;
    }

    const LastChangeTag = () => {
        if (selectedLastChange?.type) {
            const days = selectedLastChange.days || 21;
            return (
                <Tag closable={true} onClose={() => {
                    setSelectedLastChange({ days: 21 });
                }} style={{ marginTop: '12px', fontWeight: 'bold' }}>
                    {`${selectedLastChange.type} - ${days} days`}
                </Tag>
            )
        }
        return null;
    }
    return (
        <div style={{ display: 'flex' }}>
            <LabelTag />
            <LastChangeTag />
            <SortingTag />
        </div>
    );
}

const NavDrawer = ({
    isDrawerOpen, setDrawerOpen, selectedLabel, setSelectedLabel, selectedSorting, setSelectedSorting, selectedLastChange, setSelectedLastChange, showDisabled, setShowDisabled
}) => {
    const ClientLabel = () => {
        const labels = AvailableClientLabels;

        function tagRender(props) {
            const { label, value, closable, onClose } = props;
            const currLabel = labels.find(d => d.value === value);
            if (!currLabel) {
                return null;
            }
            return (
                <Tag color={currLabel.color} closable={closable} onClose={onClose} style={{ marginRight: 3, fontWeight: 'bold' }}>
                    {label}
                </Tag>
            );
        }

        return (
            <Select
                tagRender={tagRender}
                mode="tags"
                allowClear
                onClear={(e) => { }}
                options={labels}
                defaultValue={[]}
                style={{ width: '100%' }}
                value={selectedLabel ? [selectedLabel] : []}
                placeholder="Labels..."
                onChange={(values) => {
                    const length = values.length;
                    if (length > 0) {
                        setSelectedLabel(values?.[length - 1]);
                    } else {
                        setSelectedLabel();
                    }
                    setDrawerOpen(false);
                }}
            />
        );
    }
    const history = useHistory();

    return (
        <Drawer
            title="Settings"
            placement={'left'}
            closable={true}
            onClose={() => {
                setDrawerOpen(false);
            }}
            visible={isDrawerOpen}
            key={'left'}
        >
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <div style={{ flexGrow: 1 }}>
                    <div>
                        <Divider orientation="left" style={{ marginTop: 0 }}>Reminders</Divider>
                        <Button type='ghost' onClick={() => {
                            history.push(`/reminders`);
                            setDrawerOpen(false);
                        }} icon={<CheckOutlined />}> Reminders  </Button>
                    </div>
                    <div style={{ marginTop: '12px' }}>
                        <Divider orientation="left" style={{ marginTop: 0 }}>Labels</Divider>
                        <ClientLabel />
                    </div>
                    <div style={{ marginTop: '12px' }}>
                        <Divider orientation="left" style={{ marginTop: 0 }}>Days since...</Divider>
                        <ClientLastChange {...{ selectedLastChange, setSelectedLastChange, setDrawerOpen }} />
                    </div>
                    <div style={{ marginTop: '12px' }}>
                        <Divider orientation="left" style={{ marginTop: 0 }}>Sorting</Divider>
                        <ClientsSorting {...{ selectedSorting, setSelectedSorting, setDrawerOpen }} />
                    </div>
                    <div style={{ marginTop: '12px' }}>
                        <Divider orientation="left" style={{ marginTop: 0 }}>Add Client</Divider>
                        <Button type='ghost' onClick={() => {
                            history.push(`/client/new`);
                            setDrawerOpen(false);
                        }} icon={<UserAddOutlined />}> Add  </Button>
                    </div>
                    <div style={{ marginTop: '12px' }}>
                        <Divider orientation="left" style={{ marginTop: 0 }}>Disabled clients</Divider>
                        <Checkbox
                            checked={showDisabled}
                            onChange={(e) => {
                                setShowDisabled(e.target.checked);
                                setDrawerOpen(false);
                            }}
                        >
                            Show Disabled Clients
                        </Checkbox>
                    </div>
                </div>
                <div>
                    <Button type='ghost' onClick={() => {
                        Firebase.logOutUser();
                    }} icon={<LogoutOutlined />}> Signout  </Button>
                    <span> NH - v0.2.0 </span>
                </div>
            </div>
        </Drawer>
    );
};

function ClientLastChange({ selectedLastChange, setSelectedLastChange, setDrawerOpen }) {
    let days = 0;
    let type = '';
    if (selectedLastChange) {
        days = selectedLastChange.days;
        type = selectedLastChange.type;
    }
    return (
        <div style={{ display: 'flex' }}>
            <Select
                allowClear
                onClear={(e) => { }}
                options={AvailableClientLabels}
                style={{ width: '100%' }}
                value={type || null}
                placeholder="Type"
                onChange={(value) => {
                    setSelectedLastChange({
                        ...(selectedLastChange || {}),
                        type: value
                    });
                }}
            />
            <InputNumber min={1} defaultValue={21} onChange={(value) => {
                setSelectedLastChange({
                    ...(selectedLastChange || {}),
                    days: value
                });
            }} value={days} placeholder='Days...' style={{ width: '200px' }} />
        </div>
    )
}

function ClientsSorting({ selectedSorting = 'startDate', setSelectedSorting, setDrawerOpen }) {
    const options = [
        {
            label: 'Start Date',
            value: 'startDate'
        },
        {
            label: 'Alphabetical',
            value: 'alphabetical'
        }
    ]
    return (
        <div>
            <Select
                allowClear
                onClear={(e) => { }}
                options={options}
                style={{ width: '100%' }}
                value={selectedSorting}
                defaultValue={'startDate'}
                placeholder="Sorting..."
                onChange={(value) => {
                    setSelectedSorting(value);
                    setDrawerOpen(false);
                }}
            />
        </div>
    )
}

const AdminHomeDesktop = () => {
    const { data} = useFirebaseData();
    if (!data) {
        return (
            <div> Loading... </div>
        )
    }
    return (
        <div className='admin-home-main-container desktop'>
            <div className='admin-home-client-list'>
                <Switch>
                    <Route exact path="/client/:uuid">
                        <HomeTab />
                    </Route>
                    <Route>
                        <HomeTab />
                    </Route>
                </Switch>
            </div>
            <div className='admin-home-client-details'>
                <Switch>
                    <Route exact path="/client/new">
                        <NewClient />
                    </Route>
                    <Route exact path="/client/:uuid">
                        <NewClientHome />
                    </Route>
                    <Route exact path="/reminders">
                        <Reminders />
                    </Route>
                    <Route>
                        <> </>
                    </Route>
                </Switch>
            </div>
        </div>
    );
}

const AdminHomeMobile = () => {
    const { data } = useFirebaseData();
    if (!data) {
        return (
            <div> Loading... </div>
        )
    }
    return (
        <Tabs tabs={TABS}
            initialPage={0}
            tabBarPosition="bottom"
            renderTab={tab => <span>{tab.title}</span>}
        >
            <>
                <HomeTab />
            </>
            <>
                <NewClient />
            </>
            <>
                <Reminders />
            </>
        </Tabs>
    );
}

const AdminHome = () => {
    const layout = useLayout();
    if (layout === 'desktop') {
        return (
            <AdminHomeDesktop />
        );
    } else {
        return (
            <AdminHomeMobile />
        );
    }
}

export default AdminHome;
