import { Route, Switch } from 'react-router-dom';
import '../App.css';
import './AdminApp.css';
import NewClient from './pages/NewClient';
import AdminHome from './pages/AdminHome';
import NewClientHome from './pages/NewClientHome';
import { Spin } from 'antd';
import { useLayout } from '../utils/hooks/use-media';
import { useRequireAuth } from '../utils/hooks/use-require-auth';
import { ProvideFirebaseData, useFirebaseData } from '../utils/hooks/use-firebase';

const React = require('react');

const AdminAppMobile = () => {
    const {status, data} = useFirebaseData();
    if (['loading', 'idle'].includes(status) || !data) {
        return (
            <div style={{width: "100vw", height: "100vh", margin: "auto", display: 'flex'}}>
                <Spin style={{ margin: 'auto' }} />
            </div>
        );
    }
    return (
        <div className='nh-modal-container'>
            <div className='nh-frame-common' >
                <div className='gh-portal-popup-background preview'></div>
                <div className='gh-portal-popup-wrapper'>
                    <div className='gh-portal-popup-container preview gh-portal-container-wide' tabIndex={-1}>
                        <Switch>
                            <Route exact path="/client/new">
                                <NewClient />
                            </Route>
                            <Route exact path="/client/:uuid">
                                <NewClientHome />
                            </Route>
                            <Route>
                                <AdminHome />
                            </Route>
                        </Switch>
                    </div>
                </div>
            </div>
        </div>
    );
}

const AdminAppDesktop = () => {
    const {status, data} = useFirebaseData();
    if (['loading', 'idle'].includes(status) || !data) {
        return (
            <div style={{ width: "100vw", height: "100vh", margin: "auto", display: 'flex' }}>
                <Spin style={{ margin: 'auto' }} />
            </div>
        );
    }
    return (
        <div className='nh-modal-container'>
            <div className='nh-frame-common' >
                <div className='gh-portal-popup-container preview gh-portal-container-desktop' tabIndex={-1}>
                    <AdminHome />
                </div>
            </div>
        </div>
    );
}

const AdminApp = () => {
    const layout = useLayout();
    const auth = useRequireAuth('/login');

    if (auth.user === null) {
        return (
            <div style={{ width: "100vw", height: "100vh", margin: "auto", display: 'flex' }}>
                <Spin style={{ margin: 'auto' }} />
            </div>
        );
    }
    const AdminAppLayout = layout === 'desktop' ? AdminAppDesktop : AdminAppMobile;
    return (
        <ProvideFirebaseData>
            <AdminAppLayout />
        </ProvideFirebaseData>
    );
}

export default AdminApp;