import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import React from 'react';

const mediaContext = createContext(null);

/**
 * Provider component that wraps generic media query for app and makes media object available to any child component that calls useLayout().
 */
export function ProvideMedia({ children }) {
    const layout = useMedia(
        ['(min-width: 1024px)', '(min-width: 768px) and (max-width: 1023px)', '(max-width: 767px)'],
        ['desktop', 'tablet', 'mobile'],
        'desktop'
    );
    return <mediaContext.Provider value={layout}>{children}</mediaContext.Provider>;
}

/**
 * Hook for child components to get the current media layout - desktop, tablet, mobile
 * @return {('mobile'|'desktop'|'tablet')} layoutType - The layout based on current media
 */
export const useLayout = () => {
    return useContext(mediaContext);
};

/**
 * Hook to utilize media queries
 * @param {string[]} queries List of media queries
 * @param {*[]} values Value corresponding to media query
 * @param {*} defaultValue Default value
 * @return {*} Value based on current media
 */
export function useMedia(queries, values, defaultValue) {
    // Array containing a media query list for each query
    const mediaQueryLists = queries.map(q => window.matchMedia(q));

    // Function that gets value based on matching media query
    const getValue = useCallback(
        () => {
            // Get index of first media query that matches
            const index = mediaQueryLists.findIndex(mql => mql.matches);
            // Return related value or defaultValue if none
            return typeof values[index] !== 'undefined' ? values[index] : defaultValue;
        },
        [mediaQueryLists, values, defaultValue],
    )

    // State and setter for matched value
    const [value, setValue] = useState(getValue);

    useEffect(
        () => {
            // Event listener callback
            // Note: By defining getValue outside of useEffect we ensure that it has ...
            // ... current values of hook args (as this hook callback is created once on mount).
            const handler = () => setValue(getValue);
            // Set a listener for each media query with above handler as callback.
            mediaQueryLists.forEach(mql => mql.addListener(handler));
            // Remove listeners on cleanup
            return () => mediaQueryLists.forEach(mql => mql.removeListener(handler));
        },
        [getValue, mediaQueryLists] // Empty array ensures effect is only run on mount and unmount
    );

    return value;
}