import React from 'react';
import Logo from '../../images/logo.png';
import ProfileComponent from '../common/ProfileComponent';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import './Login.css';
import * as firebase from "firebase/app";

require('firebase/auth');

const StyledFirebaseAuthUI = () => {
    const uiConfig = {
        signInFlow: 'popup',
        signInSuccessUrl: '/',
        signInOptions: [
            {
                provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
                recaptchaParameters: {
                    type: 'image',
                    size: 'invisible'
                },
                defaultCountry: 'IN'
            }
        ],
    }
    return (
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
    )
}

const LoginPage = () => {
    return (
        <div style={{marginTop: '18px', width: '100vw', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div>
                <div style={{display:'flex', justifyContent: 'center'}}>
                    <img src={Logo} alt='NH Logo' style={{ height: '80px' }} />
                </div>
                <div style={{
                    display: 'flex', justifyContent: 'center', fontWeight: 'bold', fontSize: '18px', fontFamily: "'Fira Sans', sans-serif", marginBottom: '20px'
                }}>
                    Nurturing Health
                </div>
                {/* <CustomeFirebaseUI /> */}
                <StyledFirebaseAuthUI />

                <div style={{marginTop: '12px', display: 'flex', justifyContent: 'center'}}>
                    <ProfileComponent />
                </div>
            </div>
        </div>
    );
}

export default LoginPage;