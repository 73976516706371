import { useEffect, useState } from "react";
import Firebase from "../firebase";
import { _deepDifference } from "../helpers";
import { logger } from "../logger";

const DELAY = 1500;

/**
 * @typedef {import('../../services/users').UserList} UserList
 */

/**
 *
 * @param {UserList} userList
 */
export const useFirebaseSave = (userList) => {
    const [isSaving, setIsSaving] = useState(false);
    useEffect(() => {
        console.log("Effect triggered with", userList.lastModifiedAt, userList);
        const timer = setTimeout(() => {
            logger("Checking firebase update", userList.lastModifiedAt );
            const changedUserList = userList.filter((user) => {
                return !(user.originalData.lastModifiedAt.isEqual(user.lastModifiedAt))
            });
            logger("Found updated users", changedUserList.users);
            if (changedUserList.length > 0) {
                setIsSaving(true);
                const WriteBatch = Firebase.db.batch();
                changedUserList.forEach((user) => {
                    const diff = _deepDifference(user.data, user.originalData);
                    const newToOlddiff = _deepDifference(user.originalData, user.data);
                    logger("Saving changes", diff, newToOlddiff);
                    const ref = Firebase.db.doc(user.ref);
                    WriteBatch.update(ref, user.data);
                })
                logger("Commiting change to firestore", changedUserList.users);
                WriteBatch.commit().then(() => {
                    setIsSaving(false);
                    logger("Write done");
                });
            }
        }, DELAY)
        return () => clearTimeout(timer);
    }, [userList, userList.lastModifiedAt]);
    return {isSaving};
};

/**
 *
 * @param {import('../../services/users'.ReminderList} reminderList
 */
export const useFirebaseReminderSave = (reminderList) => {
    const [isSaving, setIsSaving] = useState(false);
    useEffect(() => {
        console.log("Effect triggered with", reminderList.lastModifiedAt, reminderList);
        const timer = setTimeout(() => {
            logger("Checking firebase update", reminderList.lastModifiedAt );
            const changedReminderList = reminderList.filter((reminder) => {
                return !(reminder.originalData.lastModifiedAt.isEqual(reminder.lastModifiedAt))
            });
            logger("Found updated reminders", changedReminderList.data);
            if (changedReminderList.length > 0) {
                setIsSaving(true);
                const WriteBatch = Firebase.db.batch();
                changedReminderList.forEach((reminder) => {
                    const diff = _deepDifference(reminder.data, reminder.originalData);
                    const newToOlddiff = _deepDifference(reminder.originalData, reminder.data);
                    logger("Saving changes", diff, newToOlddiff);
                    const ref = Firebase.db.doc(reminder.ref);
                    WriteBatch.update(ref, reminder.data);
                })
                logger("Commiting change to firestore", changedReminderList.reminders);
                WriteBatch.commit().then(() => {
                    setIsSaving(false);
                    logger("Write done");
                });
            }
        }, DELAY)
        return () => clearTimeout(timer);
    }, [reminderList, reminderList.lastModifiedAt]);
    return {isSaving};
};
