import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Firebase from './utils/firebase';

Firebase.initialize();

ReactDOM.render(
  <App />,
  document.getElementById('root')
);