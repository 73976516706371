import React from 'react';
import { Form, Input, Button, Select } from 'antd';
import DatePicker from '../DatePicker';
import { useEffect } from 'react';
import { format } from 'date-fns';
import { useFirebaseData } from '../../utils/hooks/use-firebase';
import { addUserToFirebase } from '../../services/users';

var Faker = require('faker');
Faker.locale = 'en_IND';

const InputContainer = (props) => {
    return (
        <div style={{padding: '6px 0'}}>
            <Input {...props} />
        </div>
    )
}

function getNewUserId({users = []}) {
    const idNos = users.filter((d) => {
        return (d.id && typeof d.id === 'string' && d.id.startsWith('NH'));
    }).map((d) => {
        return Number(d.id.replace('NH', ''))
    }).sort((a, b) => {
        return b - a;
    });
    if (idNos.length > 0) {
        const newId = idNos[0] + 1;
        return `NH${newId}`;
    }
    return Faker.random.uuid();
}

function getFormattedStartDate(startDate) {
    if (startDate) {
        return format(startDate, 'dd/MM/yy');
    }
    return '';
}

const NewClient = () => {
    const {data} = useFirebaseData();
    const [form] = Form.useForm();
    const users = data?.users || [];
    const newUserId = getNewUserId({ users })

    useEffect(() => {
        form.resetFields();
        form.setFieldsValue({ id: newUserId });
    }, [data, form, newUserId])

    const onFormChange = (...values) => {};

    const onFormSubmit = (values) => {
        if (!values.id) {
            return;
        }

        const newUser = {
            uuid: Faker.random.uuid(),
            id: values.id,
            mobile: values.mobile || '',
            name: values['name'] || '',
            type: values['type'] || '',
            plan: {
                startDate: getFormattedStartDate(values.startDate),
                amount: values.planAmount || '',
                months: values.planMonths || '',
                reference: values.planReference || ''
            },
            progression: [],
            notes: {
                postpartum: values.postpartum || '',
                info: values.info || '',
                why: values.why || '',
                touchpoints: values.touchpoints || '',
                bmr: values.bmr || '',
                height: values.height || '',
                age: values.age || '',
                food: values.food || '',
                foodPref: ''
            },
            planUpdates: [],
            labels: []
        }

        addUserToFirebase(newUser);
    };

    return (
        <div className={'gh-portal-content fullscreen'}>
            <Form
                layout={'vertical'}
                form={form}
                onValuesChange={onFormChange}
                onFinish={onFormSubmit}
                initialValues={{
                    id: newUserId,
                    planAmount: 8000,
                    type: 'self',
                    planReference: '-',
                    planMonths: 3
                }}
            >
                <Form.Item label="ID" name='id'>
                    <InputContainer placeholder="NH1xx" disabled/>
                </Form.Item>
                <Form.Item
                    label="Name" name='name'
                    rules={[{ required: true, message: 'Please add name!' }]}
                >
                    <InputContainer placeholder="Client Name" />
                </Form.Item>
                <Form.Item label="Mobile" name='mobile'>
                    <InputContainer placeholder="Mobile" />
                </Form.Item>
                <Form.Item label="Start Date" name='startDate'>
                    <DatePicker
                        format='DD MMM'
                    />
                </Form.Item>
                <Form.Item label="Type" name='type'>
                    <Select
                        placeholder="Select client type"
                    >
                        <Select.Option value="self">Self</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label="Post-Partum / DR " name='postpartum'>
                    <InputContainer placeholder="Post-Partum and DR" />
                </Form.Item>
                <Form.Item label="Touchpoints" name='touchpoints'>
                    <Input.TextArea
                        placeholder="Touchpoints"
                        autoSize={{ minRows: 1, maxRows: 3 }}
                    />
                </Form.Item>
                <Form.Item label="Why" name='why'>
                    <InputContainer placeholder="Why" />
                </Form.Item>
                <Form.Item label="Info" name='info'>
                    <Input.TextArea
                        placeholder="Info"
                        autoSize={{ minRows: 1, maxRows: 3 }}
                    />
                </Form.Item>
                <Form.Item label="Age" name='age'>
                    <Input placeholder="Age" />
                </Form.Item>
                <Form.Item label="Height" name='height'>
                    <Input placeholder="Height(in cm)" />
                </Form.Item>
                <Form.Item label="BMR/TDEEE" name='bmr'>
                    <Input placeholder="BMR and TDEEE" />
                </Form.Item>
                <Form.Item label="Plan (months)" name='planMonths'>
                    <Input placeholder="Plan (Months)" type='number' />
                </Form.Item>
                <Form.Item label="Plan Amount" name='planAmount'>
                    <Input placeholder="Plan Amount (Rs.)" type='number' />
                </Form.Item>
                <Form.Item label="Reference" name='planReference'>
                    <Input placeholder="Client Reference" />
                </Form.Item>
                <Form.Item style={{marginTop: '24px'}}>
                    <Button type="primary" style={{ width: '100%' }} htmlType="submit">Submit</Button>
                </Form.Item>
            </Form>
        </div>
    );
};
export default NewClient;
