import * as firebase from "firebase/app";
// Add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";

const Firebase = {
    /**
     * Initialize default Firebase app
     * @returns {firebase.app.App} The default firebase app that holds the initialization information for a collection of services.
     */
    initialize() {
        // Initialize Cloud Firestore through Firebase
        const firebaseConfig = {
            apiKey: "AIzaSyCV_lvnaZzB-4KlZd2u14x5xCogdFFRAMQ",
            authDomain: "nurturinghealth-75209.firebaseapp.com",
            databaseURL: "https://nurturinghealth-75209.firebaseio.com",
            projectId: "nurturinghealth-75209",
            storageBucket: "nurturinghealth-75209.appspot.com",
            messagingSenderId: "71726585559",
            appId: "1:71726585559:web:0d37b7283e230999784292",
            measurementId: "G-JZDBKS59S9"
        };
        const app = firebase.initializeApp(firebaseConfig);
        firebase.auth(app).useDeviceLanguage();
        firebase.analytics();
        return app;
    },

    logOutUser() {
        firebase.auth().signOut();
    },

    /**
     * Check if firebase app is initialized
     * @returns {Boolean}
     */
    get isInitialized() {
        return firebase.apps.length > 0;
    },

    /**
     * Get `firebase.auth`
     */
    get auth() {
        return firebase.auth;
    },

    /**
     * Get `firebase.firestore`
     */
    get firestore() {
        return firebase.firestore;
    },

    /**
     * Get Firestore DB
     */
    get db() {
        if (!this.isInitialized) {
            return null;
        }
        return firebase.firestore();
    }
}

export function firebaseFns() {
    return {
        Firebase: firebase,
        FirestoreDb: firebase.firestore(),
        Firestore: firebase.firestore
    }
}

export default Firebase;