export function logger(...args) {
    if (process.env.NODE_ENV === 'development') {
        logger.group()
        console.log(...args);
        logger.groupEnd();
    }
}

logger.log = function(...args) {
    if (process.env.NODE_ENV === 'development') {
        console.log(...args);
    }
}

logger.group = function(...args) {
    if (process.env.NODE_ENV === 'development') {
        const dashLine = "-".repeat(10);
        console.group(`%c${dashLine}NH${dashLine}`, 'color:red');
    }
}

logger.groupEnd = function (...args) {
    if (process.env.NODE_ENV === 'development') {
        console.groupEnd();
    }
}