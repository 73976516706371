import Firebase from "../utils/firebase";
const { parse, add, isAfter, isSaturday, isSunday, isBefore, format, sub } = require('date-fns');
var Faker = require('faker');
Faker.locale = 'en_IND';

export async function generateReminders(user) {
    let reminders = [];
    [user].forEach((user) => {
        reminders = reminders.concat(generateRemindersForUser(user));
    })

    reminders = reminders.filter((d) => {
        const parsedDate = parse(d.reminderDate, 'dd/MM/yy', new Date());
        return isAfter(parsedDate, new Date());
    });

    const WriteBatch = Firebase.db.batch();
    reminders.forEach((d) => {
        WriteBatch.set(Firebase.db.doc(`reminders/${d.uuid}`), {
            ...d,
            createdAt: Firebase.firestore.Timestamp.now(),
            lastModifiedAt: Firebase.firestore.Timestamp.now()
        })
    });

    await WriteBatch.commit();
    return reminders;
}

export async function deleteReminders(user) {
    const querySnapshot = await Firebase.db.collection('reminders').where('user_uuid', '==', user.uuid).get();
    const snapshotDocs = querySnapshot.docs;

    const WriteBatch = Firebase.db.batch();

    for (let snapshotDoc of snapshotDocs) {
        WriteBatch.update(snapshotDoc.ref, {
            deleted: true,
            lastModifiedAt: Firebase.firestore.Timestamp.now()
        })
    }

    await WriteBatch.commit();
    return;
}

function generateRemindersForUser(user) {
    const startDate = user.plan.startDate;
    const parsedStartDate = parse(startDate, 'dd/MM/yy', new Date())
    let plan = Number(user.plan.months) || 3;
    if (!plan || isNaN(plan)) {
        plan = 3;
    }
    const endDate = add(parsedStartDate, {
        months: plan
    });
    const isValidClient = isAfter(endDate, new Date());
    if (!isValidClient) {
        return [];
    }
    const callReminders = generateCallReminders({ user, startDate: parsedStartDate, endDate });
    const picReminders = generatePicReminders({ user, startDate: parsedStartDate, endDate });
    const planEndReminder = generatePlanEndReminder({ user, startDate: parsedStartDate, endDate });
    // const sevenDayCallReminder = generate7DayCallReminder({ user, startDate: parsedStartDate, endDate });
    return callReminders.concat(picReminders).concat(planEndReminder);
}

function generatePlanEndReminder({ user, startDate, endDate }) {
    let reminderDate = sub(endDate, { days: 10 })
    reminderDate = getNonWeekendDate(reminderDate);
    const reminders = [{
        createdAt: Firebase.firestore.Timestamp.now(),
        lastModifiedAt: Firebase.firestore.Timestamp.now(),
        user_uuid: user.uuid,
        uuid: Faker.random.uuid(),
        type: "plan-end",
        startDate: format(startDate, 'dd/MM/yy'),
        endDate: format(endDate, 'dd/MM/yy'),
        reminderDate: format(reminderDate, "dd/MM/yy"),
        deleted: false,
        finished: false,
        reason: 'plan end'
    }];
    return reminders;
}

function getNonWeekendDate(date) {
    if (isSaturday(date)) {
        return add(date, { days: 2 });
    } else if (isSunday(date)) {
        return add(date, { days: 1 });
    }
    return date;
}

export function generate7DayCallReminder({ user, startDate, endDate }) {
    let reminderDate = add(startDate, { weeks: 1 })
    reminderDate = getNonWeekendDate(reminderDate);
    const reminders = [];
    while (isBefore(reminderDate, endDate)) {
        reminders.push({
            user_uuid: user.uuid,
            createdAt: Firebase.firestore.Timestamp.now(),
            lastModifiedAt: Firebase.firestore.Timestamp.now(),
            uuid: Faker.random.uuid(),
            type: "7-day-call",
            startDate: format(startDate, 'dd/MM/yy'),
            endDate: format(endDate, 'dd/MM/yy'),
            reminderDate: format(reminderDate, "dd/MM/yy"),
            deleted: false,
            finished: false,
            reason: '7 day call'
        });
        reminderDate = add(reminderDate, { weeks: 1 })
        reminderDate = getNonWeekendDate(reminderDate);
    }
    return reminders;
}

function generateCallReminders({ user, startDate, endDate }) {
    let reminderDate = add(startDate, { weeks: 3 })
    reminderDate = getNonWeekendDate(reminderDate);
    const reminders = [];
    while (isBefore(reminderDate, endDate)) {
        reminders.push({
            user_uuid: user.uuid,
            createdAt: Firebase.firestore.Timestamp.now(),
            lastModifiedAt: Firebase.firestore.Timestamp.now(),
            uuid: Faker.random.uuid(),
            type: "call",
            startDate: format(startDate, 'dd/MM/yy'),
            endDate: format(endDate, 'dd/MM/yy'),
            reminderDate: format(reminderDate, "dd/MM/yy"),
            deleted: false,
            finished: false,
            reason: '3 week - Call'
        });
        reminderDate = add(reminderDate, { weeks: 3 })
        reminderDate = getNonWeekendDate(reminderDate);
    }
    return reminders;
}

function generatePicReminders({ user, startDate, endDate }) {
    let reminderDate = add(startDate, { weeks: 2 })
    reminderDate = getNonWeekendDate(reminderDate);
    const reminders = [];
    while (isBefore(reminderDate, endDate)) {
        reminders.push({
            user_uuid: user.uuid,
            createdAt: Firebase.firestore.Timestamp.now(),
            lastModifiedAt: Firebase.firestore.Timestamp.now(),
            uuid: Faker.random.uuid(),
            type: "pic",
            startDate: format(startDate, 'dd/MM/yy'),
            endDate: format(endDate, 'dd/MM/yy'),
            reminderDate: format(reminderDate, "dd/MM/yy"),
            deleted: false,
            finished: false,
            reason: '2 week Pic'
        });
        reminderDate = add(reminderDate, { weeks: 2 })
        reminderDate = getNonWeekendDate(reminderDate);
    }
    return reminders;
}
