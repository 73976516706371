import { createContext, useContext, useEffect, useReducer } from "react";
import { logger } from '../logger';
import { getDateFromTimestamp, getLocalSerializedUsers, listenUsersData } from '../../services/api';
import User, { UserList } from "../../services/users";
import { useFirebaseSave } from "./use-firebase-save";
import { updateLocalCollectionData } from "../../services/localstorage";
import React from 'react';

const firebaseUserContext = createContext(null);

/**
 * @typedef {{status: string, data: UserList, error: any}} FirebaseQueryState
 */

/**
* @typedef {{type: string, payload?: any}} DispatchAction
*/

/**
 * @typedef {import("react").Dispatch<DispatchAction>} DispatchWithAction
 */

/**
 * Provider component that wraps generic firebase
 */
export function ProvideFirebaseData({ children }) {
    const {state, dispatch} = useFirebase();
    const { status, data, error } = state;
    const {isSaving} = useFirebaseSave(data);

    return (
        <firebaseUserContext.Provider value={{ status, data, error, isSaving, dispatch }}>{children}</firebaseUserContext.Provider>
    );
}

/**
 * Hook for child components to get firebase users
 * @returns {{data: UserList, status: String, isSaving: Boolean, dispatch: DispatchWithAction}}
 */
export const useFirebaseData = () => {
    return useContext(firebaseUserContext);
};

/**
 * @callback ReducerFn
 * @param {FirebaseQueryState} _state
 * @param {DispatchAction} action
 */

// Reducer for hook state and actions
/**
 * Array map callback method
 * @type {ReducerFn}
*/
const reducer = (_state, action) => {
    switch (action.type) {
        case "idle":
            return { status: "idle", data: (_state.data || new UserList()), error: undefined };
        case "loading":
            return { status: "loading", data: (_state.data || new UserList()), error: undefined };
        case "success":
            return { status: "success", data: action.payload, error: undefined };
        case "updateUser":
            if (action.payload && action.payload instanceof User) {
                logger("Got Updated user", action.payload.lastModifiedAt);
                return {
                    ..._state,
                    data: _state.data.updateUser(action.payload)
                };
            }
            return _state;
        case "updateFirebaseChanges":
            const {updated = [], removed = []} = action.payload;
            const updatedList = _state.data.updateUserList(updated).removeUsers(removed);
            updatedList.updateFirebaseTimestamp();
            return {
                ..._state,
                status: "success",
                data: updatedList
            };
        case "updateUserList":
            if (action.payload) {
                return {
                    data: _state.data.updateUserList(action.payload),
                    status: "success",
                    error: undefined
                };
            }
            return _state;
        case "error":
            return { status: "error", data: undefined, error: action.payload };
        default:
            throw new Error("invalid action");
    }
}

function handleFirebaseChange({collection, docChanges, dispatch}) {
    const changes = { updated: [], removed: [] }
    docChanges.forEach((docChange) => {
        const changedUser = new User({
            id: docChange.doc.id,
            ref: docChange.doc.ref.path,
            lastModifiedAt: docChange.doc.get('lastModifiedAt'),
            data: docChange.doc.data()
        });
        if (docChange.type === 'removed') {
            changes.removed.push(changedUser)
        } else {
            changes.updated.push(changedUser)
        }
    })
    if (docChanges.length > 0) {
        dispatch({ type: "updateFirebaseChanges", payload: changes });
    }
}

export const useFirebase = () => {
    /**
     * @type {FirebaseQueryState}
     */
    const initialState = {
        status: "idle",
        data: new UserList(),
        error: undefined
    };

    /**
    * @type {[FirebaseQueryState, DispatchWithAction]}
    */
    const [state, dispatch] = useReducer(reducer, initialState);
    const collection = "users";

    const lastFirebaseUpdate = state?.data?.lastFirebaseUpdateTimestamp;
    useEffect(() => {
        const isoTimestamp = getDateFromTimestamp(state.data.getLastModifiedUserTime());
        if (state.data && isoTimestamp) {
            logger("Saving to local storage", isoTimestamp, collection, state.data.toJSON());
            updateLocalCollectionData({ collection, data: state.data.toJSON(), timestamp: isoTimestamp })
        }
    }, [state.data, lastFirebaseUpdate]);


    useEffect(() => {
        let unsubscribeFirebaseRef;
        dispatch({ type: 'loading' });
        const fetchUserData = async () =>{
            const { data, lastUpdatedAt } = await getLocalSerializedUsers({ collection });
            if (data  && lastUpdatedAt) {
                logger("Found initial data in localstorage", data, lastUpdatedAt);
                dispatch({ type: "updateUserList", payload: data });
            }
            listenUsersData(({ docChanges }) => {
                handleFirebaseChange({docChanges, dispatch, collection});
            }, lastUpdatedAt).then((ref) => {
                unsubscribeFirebaseRef = ref;
            });
        };
        fetchUserData();
        return () => {
            // Clean up the subscription
            unsubscribeFirebaseRef && unsubscribeFirebaseRef();
        };
    }, []);
    return { state, dispatch };
}