import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import AdminApp from './components/AdminApp';
import { AppProvider } from './components/AppProvider';
import LoginPage from './components/pages/Login';
import TagManager from './components/pages/TagManager';
import ServiceWorkerWrapper from './components/ServiceWorkerWrapper';

function App() {

    return (
        <>
            <AppProvider>
                <BrowserRouter>
                    <Switch>
                        <Route path="/instatags" render={routeProps => (<TagManager {...routeProps} />)} />
                        <Route path="/login">
                            <LoginPage />
                        </Route>
                        <Route path="/">
                            <AdminApp />
                        </Route>
                    </Switch>
                </BrowserRouter>
            </AppProvider>
            <ServiceWorkerWrapper />
        </>
    );
}

export default App;
