import React, { useState } from 'react';
import { List, Avatar, Checkbox } from 'antd';
import { useFirebaseData } from '../../utils/hooks/use-firebase';
import { Link } from 'react-router-dom';
import { ProvideFirebaseReminderData, useFirebaseReminderData } from '../../utils/hooks/use-firebase-reminders';
import formatDate from 'date-fns/format';
import parseDate from 'date-fns/parse';
import dateDifferenceInWeeks from 'date-fns/differenceInWeeks'

const ClientAvatar = ({ profilePic, name }) => {
    const firstLetter = name?.[0] || 'U';
    if (profilePic) {
        return (
            <Avatar src={profilePic} style={{
                borderRadius: '50%',
                width: '100%',
                height: '100%',
                overflow: 'hidden'
            }} />
        )
    } else {
        return (
            <Avatar
                style={{
                    backgroundColor: '#f56a00',
                    verticalAlign: 'middle',
                }}
                size="large"
            >
                {firstLetter}
            </Avatar>
        );
    }
};

function ActionCheckbox({reminder}) {
    const [isChecked, setChecked] = useState(false);
    const {dispatch} = useFirebaseReminderData();
    return (
        <Checkbox
            checked={!!reminder.data.finished}
            onChange={() => {
                setChecked(!isChecked);
                reminder.setFinished(!isChecked);
                dispatch({type: 'updateReminder', payload: reminder});
            }}
        />
    )
}

const useClientData = ({ uuid }) => {
    const { status, data, isSaving } = useFirebaseData();
    const client = data.findByUUID(uuid);
    return { client, status, isSaving }
};

function ReminderListItem({item}) {
    const reminderData = item.data;
    const {client} = useClientData({uuid: reminderData.user_uuid});
    const typeText = {
        'call': '3 Week Call',
        'pic': '2 Week Pic',
        'plan-end': 'Plan ending soon',
    };
    return (
        <List.Item
            actions={[(<ActionCheckbox reminder={item} />)]}
        >
            <List.Item.Meta
                avatar={<ClientAvatar profilePic={client?.profilePic} name={client?.name} />}
                title={<Link to={`/client/${client.uuid}`}>{client?.name} - {typeText[reminderData.type]}</Link>}
                description={getDescription({item: reminderData, client})}
            />
        </List.Item>
    )
}

function getDayWeekText({ startDate }) {
    if (startDate) {
        const week = dateDifferenceInWeeks(new Date(), startDate);
        // const days = dateDfferenceInCalendarDays(new Date(), startDate);
        const startDateFormat = formatDate(startDate, 'dd MMM');
        return `${startDateFormat} | Week ${week + 1}`;
    }
    return '';
}

function getDateStr(date) {
    const parsedDate = parseDate(date, 'dd/MM/yy', new Date());
    if (parsedDate) {
        return formatDate(parsedDate, 'dd MMM');
    }
    return date;
}

function getDescription({item, client}) {
    const parsedStartDate = parseDate(item.startDate, 'dd/MM/yy',  new Date());
    return (
        <div>
            <div style={{fontWeight: 'bold'}}> {getDateStr(item.reminderDate)} </div>
            <div>
                {getDayWeekText({startDate: parsedStartDate})}
            </div>
        </div>
    );
    // return `Reminder: ${item.reminderDate} || Start Date: ${item.startDate} | End Date: ${item.endDate}`;
}

function RemindersMain() {
    const {data} = useFirebaseReminderData();
    const {data: clientList} = useFirebaseData();
    const [showAllReminders, setShowAllReminders] = useState(false);
    let reminderData = showAllReminders ? data?.reminders : data?.upcomingReminders;
    reminderData = reminderData.filter((d) => {
        const client = clientList.findByUUID(d.data.user_uuid);
        return !client?.data?.isRemindersDisabled && !d.data.deleted;
    });
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            overflow: 'scroll'
        }}>
            <div style={{
                fontWeight: 'bold',
                fontSize: '24px',
                textAlign: 'center',
                marginBottom: '12px',marginTop: '12px'
            }}>
                <span>Reminders </span>
                <Checkbox
                    checked={showAllReminders}
                    onChange={(e) => {
                        setShowAllReminders(e.target.checked);
                    }}
                >
                    Show All
                </Checkbox>
            </div>
            <div style={{
                margin: "0 24px",
                background: "white",
                padding: "0 12px"
            }}>
                <List
                    itemLayout="horizontal"
                    dataSource={reminderData || []}
                    renderItem={item => (
                        <ReminderListItem item={item} />
                    )}
                />
            </div>
        </div>
    );
}

export default function Reminders() {
    return (
        <ProvideFirebaseReminderData>
            <RemindersMain />
        </ProvideFirebaseReminderData>
    );
}
