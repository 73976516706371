import React, { useEffect, useState } from 'react';
import * as serviceWorker from '../serviceWorker';
import { Alert, Button } from 'antd';

const ServiceWorkerWrapper = () => {
    const [showReload, setShowReload] = React.useState(false);
    const [waitingWorker, setWaitingWorker] = useState(null);
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        serviceWorker.register({
            onUpdate: (registration) => {
                setShowReload(true);
                setWaitingWorker(registration.waiting);
            }
        });
    }, []);
    /* eslint-enable react-hooks/exhaustive-deps */

    const reloadPage = () => {
        try {
            waitingWorker && waitingWorker.postMessage({ type: 'SKIP_WAITING' });
            window.location.reload();
        }
         catch(e) {
            alert(e);
         }
    };
    if (showReload) {
        return (
            <Alert
                type="warning"
                showIcon
                message={(
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <span> New version is available </span>
                        <Button type="link" size="small" onClick={() => reloadPage()} style={{fontWeight: 'bold'}}>
                            Update Now
                        </Button>
                    </div>
                )}
                banner
                closable
            />
        )
    }
    return (
        <div style={{visibility: 'hidden'}}> For Service Worker</div>
    );
}

export default ServiceWorkerWrapper;